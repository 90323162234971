import { useContext, useEffect, useState } from "react";
import useTranslator from "../../../hooks/useTranslator";
import { Container, Form, Modal } from "react-bootstrap";
import AuthContext from "../../../context/auth/AuthContext";
import TenantContext from "../../../context/tenant/TenantContext";
import { useNavigate } from "react-router-dom";
import { BsBank2 } from "react-icons/bs";
import { haveValue, getBaseUrl, skipMaccUser } from "../../../helper/common";
import axios from "axios";
import { toast } from "react-toastify";
import { sendUniversalMail } from "../../../services/sharedService";
import { useSnackbar } from "material-ui-snackbar-provider";
import AlreadyGuarantorOf from "../../../components/AlreadyGuarantorOf";
import SomeoneGuarantor from "../../../components/SomeoneGuarantor";
import constants from "../../../Utils/constants";

const TenantBankConnect = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { user, loadUser, userType, loadUserTenant } = authContext;
  const { updateKyc } = tenantContext;
  const queryParameters = new URLSearchParams(window.location.search);
  const error1 = queryParameters.get("error");
  const account_verification_report_id = queryParameters.get(
    "account_verification_report_id"
  );
  const transaction_report_id = queryParameters.get("transaction_report_id");

  useEffect(() => {
    if (error1) {
      toast.error(_t("bankDetailsCannotBeFetched"));
    }
    if (account_verification_report_id && transaction_report_id) {
      let userid = localStorage.getItem("saveduserid");
      if (!userid) {
        toast.error(_t("userNotAuthorized"));
      } else {
        calculateScore(
          userid,
          account_verification_report_id,
          transaction_report_id
        );
      }
    }
  }, [account_verification_report_id]);

  const [openModal, setOpenModal] = useState(false);
  const [bankShow, setBankShow] = useState(false);
  const [defaultModal, setDefaultModal] = useState(true);
  const [tink_countryCode, setTink_countryCode] = useState("");
  const [countryCodeError, setCountryCodeError] = useState("");
  const [showGurantor, setShowGurantor] = useState(false);
  const [gurantorPresent, setGurantorPresent] = useState(false);
  const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false);
  const [exitReason, setExitReason] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showButton, setShowButton] = useState(0);
  const [guarantorData, setGuarantorData] = useState({
    gurantor_name: "",
    gurantor_email: "",
    gurantor_relation: "",
  });

  useEffect(() => {
    if (user?.kyc_success && !haveValue(user?.paymentScores)) {
      getAnalysisDetails();
    }
  }, []);

  const calculateScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    await clearPaymentData(userid);
    loadUserTenant(localStorage.getItem("saveduserid"));
    calculatePaymentScore(userid, accountverificationid, transactionreportid);
    setCurrentStep(1);
  };

  const calculatePaymentScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/calculatescore?tenantid=${userid}&accountverificationid=${accountverificationid}&transactionreportid=${transactionreportid}`
      )
      .then((res) => {
        let serverResponse = res.data;
        if (serverResponse.status !== "success") {
          if (serverResponse.status == "pending") {
            getAnalysisDetails();
          } else {
            toast.error(_t("skorCanNotBeCalculated"));
          }
        }
        if (serverResponse.status === "success") {
          loadUser(userType);
        }
      })
      .catch((err) => {
        let err_res = err;
        //console.log(err_res);
      });
  };

  const clearPaymentData = async (tenantid) => {
    return new Promise((resolve) => {
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/tenants/algoan-info?tenantid=${tenantid}`
        )
        .then((res) => {
          return resolve(true);
        })
        .catch((err) => {
          let err_res = err;
          toast.error(err_res.message);
          return resolve(null);
        });
    });
  };

  const openBackModal = () => {
    setOpenModal(true);
  };

  const handleGobackReason = () => {
    const data = {
      user_id: user?._id,
      exit_reason: exitReason,
    };
    updateKyc(data)
      .then((res) => {
        if (user?.docs_uploaded || skipMaccUser(user?.user_type)) {
          navigate("/tenant");
        } else {
          navigate("/tenant/document");
        }
        console.log("Status updated");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openBankModal = () => {
    setBankShow(true);
  };

  const BankhandleClose = () => {
    setBankShow(false);
  };

  const changeCountryCode = (e) => {
    setTink_countryCode(e.target.value);
  };

  const connectToBank = () => {
    if (!tink_countryCode) {
      setCountryCodeError("*Please select country code");
      return;
    } else {
      setCountryCodeError("");
    }
    var lang = "en_US";
    if (localStorage.getItem("i18nextLng") == "es") {
      lang = "es_ES";
    }
    const baseUrl = getBaseUrl();
    let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_URL_NAME}%2Ftenant%2FconnectBank&market=${tink_countryCode}&locale=${lang}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;
    window.open(tinkUrlLiveFront, "_self");
  };

  const handleShowGurantor = () => {
    setShowGurantor(true);
  };

  const handleHideGurantor = () => {
    setShowGurantor(false);
    setGuarantorData({
      gurantor_name: "",
      gurantor_email: "",
      gurantor_relation: "",
    });
  };

  const handleSubmitGurantor = async (e) => {
    e.preventDefault();
    let userid = localStorage.getItem("saveduserid");
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/tenants/gurantor/${userid}`,
        guarantorData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        if (res.data?.alreadyGurantor) {
          setGurantorPresent(true);
          setAlreadyGurantorOf(true);
          handleHideGurantor();
        } else {
          toast.success(_t("guarantorAdded"));
          loadUser(userType);
          sendMail(guarantorData?.gurantor_email, "score");
          handleHideGurantor();
          setCurrentStep(2);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.error);
      });
  };

  const sendMail = (receiverEmail, emailType) => {
    let payload = {
      receiverEmail: receiverEmail,
      senderId: user?._id,
      emailType: emailType,
    };
    sendUniversalMail(payload)
      .then((res) => {
        snackbar.showMessage(_t("emailSent"));
      })
      .catch((err) => {
        snackbar.showMessage(_t("errorSendingMail"), err?.message);
        console.log(err);
      });
  };

  const navigateBackHome = () => {
    navigate("/tenant");
  };

  const checkNextButton = () => {
    if (!haveValue(user?.onfido_kyc_success) || !user?.onfido_kyc_success) {
      return (
        <button
          className="btn btnorange step-btn w-100"
          onClick={(e) => navigate("/tenant/verifyId")}
        >
          {_t("continueToVerifyId")}
        </button>
      );
    } else if (
      !skipMaccUser(user?.user_type) &&
      (!haveValue(user?.docs_uploaded) || !user?.docs_uploaded)
    ) {
      return (
        <button
          className="btn btnorange step-btn w-100"
          onClick={(e) => navigate("/tenant/document")}
        >
          {_t("continueToProvideDocument")}
        </button>
      );
    } else {
      return <></>;
    }
  };

  const getAnalysisDetails = async (req, res) => {
    let userid = localStorage.getItem("saveduserid");

    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/checkmyanalysis/${userid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          clearInterval(myInterval);
          loadUserTenant(userType);
        })
        .catch((err) => {
          clearInterval(myInterval);
          return;
        });
    }, 2000);
  };

  return (
    <>
      {currentStep == 0 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a href={undefined} onClick={openBackModal}>
                    <img src="/images/back-btn-white.svg" alt="" />
                    {/* <span>{_t("Go Back To Home")} </span> */}
                  </a>
                </div>
              </Container>
              {/* <h3>{_t("Financial Check")} </h3> */}

              <h3 class="step-form-name mobile-model-heading">
                <img src="/images/financial-home-icon.svg" alt="" />
                <span>{_t("financialCheck")}</span>
              </h3>
            </div>
            <h3 className="step-name">
              {_t("verifyYourIncomeToAccessYourSkor")}
            </h3>
            <div className="resend-text steps-wrapper">
              <div className="">
                <div className="icon-text-box">
                  <img src="/images/home-vector.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("optionOneVerifyWithBank")}
                    </h4>
                    <p className="box-desc">
                      {_t(
                        "verifyYourIncomeInstantlyWithTheBankYouReceiveYourSalaryOn"
                      )}
                    </p>
                  </div>
                </div>
                <div className="icon-text-box">
                  <img src="/images/profile2.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("optionTwoAddAGuarantor")}
                    </h4>
                    <p className="box-desc">
                      {_t(
                        "ifYouDontHaveAnAccountOrASalaryYouCanAlwaysAddSomeoneAsYourRentGuarantor"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="btn btnorange step-btn"
                onClick={openBankModal}
              >
                {_t("verifyWithBank")}
              </button>
              {user?.have_gurantor == true || user?.have_gurantor == "true" ? (
                <button
                  className="btn btnoutline bg-none"
                  onClick={(e) => {
                    sendMail(user?.gurantor_email, "score");
                    setCurrentStep(2);
                  }}
                >
                  {_t("resendInvitationToGuarantor")}
                </button>
              ) : (
                <button
                  className="btn btnoutline bg-none mt-3"
                  onClick={handleShowGurantor}
                >
                  {_t("addGuarantor")}
                </button>
              )}
            </div>
            <Modal
              className="modal-uploadpdf radio-wrapper"
              show={openModal}
              onHide={(e) => setOpenModal(false)}
              centered={window.screen.width <= 767}
            >
              <Modal.Header closeButton>
                <h5 className="modal-main-heading">
                  {_t("whyDoYouWantToLeave")}
                </h5>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-sub-heading">{_t("pleaseChooseOne")}</p>
                <label class="customradiobar">
                  <input
                    type="radio"
                    name="go_back_reason"
                    onClick={navigateBackHome}
                  />
                  <div class="boxes">{_t("iWillDoThisLater")}</div>
                </label>
                <label class="customradiobar">
                  <input
                    type="radio"
                    name="go_back_reason"
                    onClick={(e) => setExitReason("My bank is not available")}
                  />
                  <div class="boxes">{_t("myBankIsNotAvailable")}</div>
                </label>
                <label class="customradiobar">
                  <input
                    type="radio"
                    name="go_back_reason"
                    onClick={(e) =>
                      setExitReason(
                        "I don't feel confortable verifying my account"
                      )
                    }
                  />
                  <div class="boxes">
                    {_t("iDontFeelConfortableVerifyingMyAccount")}
                  </div>
                </label>

                {haveValue(exitReason) && (
                  <button
                    className="btn btnorange w-100"
                    onClick={handleGobackReason}
                  >
                    {user?.docs_uploaded || skipMaccUser(user?.user_type)
                      ? _t("goBackHome")
                      : _t("continueToProvideYourDocuments")}
                  </button>
                )}
              </Modal.Body>
            </Modal>
            <Modal
              className="modal-uploadpdf"
              show={defaultModal}
              onHide={(e) => setDefaultModal(false)}
              centered={window.screen.width <= 767}
            >
              <Modal.Header closeButton>
                <h5 className="modal-main-heading">{_t("howDoesItWork")}</h5>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-sub-heading">
                  {_t("verifyYourFinancialsInThreeSimpleSteps")}:
                </p>
                <div className="icon-text-box">
                  <img src="/images/home-icon2.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("connectYourBankAccount")}
                    </h4>
                    <p className="box-desc">
                      {_t(
                        "useYourCredentialsToAccessYourAccountInASecureManner"
                      )}
                    </p>
                  </div>
                </div>

                <div className="icon-text-box">
                  <img src="/images/picture-icon2.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">{_t("accessYourMovements")}</h4>
                    <p className="box-desc">
                      {_t("toShowYourFinancialSolvency")}
                    </p>
                  </div>
                </div>
                <div className="icon-text-box">
                  <img src="/images/meter-icon.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("generateYourFinancialSkor")}
                    </h4>
                    <p className="box-desc">
                      {_t("soYouCanGetBenefitFromAGoodFinancialBehaviour")}
                    </p>
                  </div>
                </div>
                <button
                  className="btn btnorange step-btn w-100"
                  onClick={(e) => setDefaultModal(false)}
                >
                  {_t("continue")}
                </button>
              </Modal.Body>
            </Modal>
            <Modal
              show={bankShow}
              onHide={BankhandleClose}
              centered
              className="newmodalui connectbankmodal"
              size="lg"
            >
              <Modal.Body className="p-0">
                <div className="row">
                  <div className="col-sm-6 p-2 bankimagediv">
                    <p>
                      {_t(
                        "youWillNowBeRedirectedToConnectYourBankAccountThroughOurPartnerTinkPoweredByVisa"
                      )}
                    </p>
                    <p>
                      {_t(
                        "itsImportantForYouToKnowThatWeDoNotHaveDirectAccessToYourBankLoginDetails"
                      )}
                      {_t(
                        "thisProcessIsDesignedToMaintainYourCredentialsEncriptedFromSkorOrAnyOtherThirdParty"
                      )}
                    </p>
                    <img src="../images/connect_bank.svg" alt="bank_logo" />
                    <p>
                      {_t(
                        "weAssureYouOfTheUtmostPrivacyAndSecurityOfYourFinancialData"
                      )}
                    </p>
                  </div>
                  <div className="col-sm-6 p-2 connectbankdiv">
                    <h3>{_t("connectBank")}</h3>
                    <Form className="loginform">
                      <div className="auth-form">
                        <div className="forminput row100">
                          <label className="forminput">{_t("country")}</label>
                          <select
                            className="form-control"
                            onChange={changeCountryCode}
                          >
                            <option value="">{_t("selectYourCountry")}</option>
                            <option value="BE">{_t("belgium")}</option>
                            <option value="DE">{_t("germany")}</option>
                            <option value="DK">{_t("denmark")}</option>
                            <option value="EE">{_t("estonia")}</option>
                            <option value="ES">{_t("spain")}</option>
                            <option value="FI">{_t("finland")}</option>
                            <option value="FR">{_t("france")}</option>
                            <option value="GB">{_t("unitedKingdom")}</option>
                            <option value="IT">{_t("italy")}</option>
                            <option value="LT">{_t("lithuania")}</option>
                            <option value="LV">{_t("latvia")}</option>
                            <option value="NL">{_t("netherlands")}</option>
                            <option value="NO">{_t("norway")}</option>
                            <option value="PL">{_t("poland")}</option>
                            <option value="PT">{_t("portugal")}</option>
                            <option value="SE">{_t("sweden")}</option>
                          </select>
                        </div>
                        <div className="forminput row100">
                          <label className="forminput">
                            {_t("connectBank")}
                          </label>

                          <div className="bankname" onClick={connectToBank}>
                            <BsBank2 />
                            {_t("connectBank")}
                          </div>
                          <span className="addRedColor">
                            {countryCodeError}
                          </span>
                        </div>
                      </div>
                    </Form>
                    <div className="bank-btm">
                      {_t("shouldYouHaveAnyConcernsOrQuestionsPlease")}
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/bank")}
                        className="btnlink"
                      >
                        {_t("readHere")}
                      </a>
                      {_t("orContactUsAt")}
                      <a href="mailTo:info@skortorent.com" className="btnlink">
                        info@skortorent.com.
                      </a>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={showGurantor}
              onHide={handleHideGurantor}
              centered
              className="newmodalui"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5 className="modal-main-heading mb-3">
                    {_t("addGuarantor")}
                  </h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmitGurantor}>
                  <div className="auth-form">
                    <div className="forminput row100">
                      <label className="forminput label-text">
                        {_t("nameOfGuarantor")}
                      </label>
                      <input
                        type="text"
                        name="gurantor_name"
                        placeholder={_t("nameOfGuarantor")}
                        onChange={(e) =>
                          setGuarantorData((prevData) => ({
                            ...prevData,
                            gurantor_name: e.target.value,
                          }))
                        }
                        value={guarantorData.gurantor_name}
                      />
                    </div>
                    <div className="forminput row100">
                      <label className="forminput label-text">
                        {_t("emailOfGuarantor")}
                      </label>
                      <input
                        type="text"
                        name="gurantor_email"
                        placeholder={_t("emailOfGuarantor")}
                        onChange={(e) =>
                          setGuarantorData((prevData) => ({
                            ...prevData,
                            gurantor_email: e.target.value?.toLowerCase(),
                          }))
                        }
                        value={guarantorData.gurantor_email}
                      />
                    </div>
                    <div className="forminput row100">
                      <label className="forminput label-text">
                        {_t("relationshipWithGuarantor")}
                      </label>
                      <select
                        name="gurantor_relation"
                        id=""
                        onChange={(e) =>
                          setGuarantorData((prevData) => ({
                            ...prevData,
                            gurantor_relation: e.target.value,
                          }))
                        }
                      >
                        <option value="" selected>
                          {_t("pleaseSelect")}
                        </option>
                        <option value="Father">{_t("father")}</option>
                        <option value="Mother">{_t("mother")}</option>
                        <option value="Friend">{_t("friend")}</option>
                        <option value="Relative">{_t("relative")}</option>
                        <option value="Other">{_t("other")}</option>
                      </select>
                    </div>
                    <div className="btngroup row100 gridbar">
                      <button
                        className="btn btnoutline"
                        type="button"
                        onClick={handleHideGurantor}
                      >
                        {_t("cancel")}
                      </button>
                      <button
                        className="btn btnorange"
                        type="submit"
                        disabled={
                          haveValue(guarantorData.gurantor_name) &&
                          haveValue(guarantorData.gurantor_email) &&
                          haveValue(guarantorData.gurantor_relation)
                            ? false
                            : true
                        }
                      >
                        {_t("submit")}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            {alreadyGurantorOf && gurantorPresent == false && (
              <AlreadyGuarantorOf
                alreadyGurantorOf={alreadyGurantorOf}
                setAlreadyGurantorOf={setAlreadyGurantorOf}
                gurantorPresent={gurantorPresent}
                setGurantorPresent={setGurantorPresent}
              />
            )}
            {alreadyGurantorOf && gurantorPresent == true && (
              <SomeoneGuarantor
                alreadyGurantorOf={alreadyGurantorOf}
                setAlreadyGurantorOf={setAlreadyGurantorOf}
                gurantorPresent={gurantorPresent}
                setGurantorPresent={setGurantorPresent}
              />
            )}
          </div>
        </>
      )}
      {currentStep == 1 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="congratulation-screen steps-wrapper">
              <div className="step-form-header">
                <h3 class="step-form-name">
                  <img src="/images/financial-home-icon.svg" alt="" />
                  <span>{_t("financialCheck")}</span>
                </h3>
                <img
                  className="congrats-image"
                  src="/images/confetti.svg"
                  alt=""
                />
                <h3 className="congrats-text">{_t("congratulations")}</h3>
                <p className="congrats-desc">
                  {_t(
                    "yourBankVerificationIsInProgressOnceVerifiedYouWillBeRewardedWithFiftyPoints"
                  )}
                </p>
                {checkNextButton()}
                <button
                  className="w-100 btn btnoutline bg-none"
                  onClick={navigateBackHome}
                >
                  {_t("goBackHome")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {currentStep == 2 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="congratulation-screen steps-wrapper">
              <div className="step-form-header">
                <h3 class="step-form-name mobile-model-heading ">
                  <img src="/images/financial-home-icon.svg" alt="" />
                  <span>{_t("financialCheck")}</span>
                </h3>
                <img
                  className="congrats-image"
                  src="/images/confetti.svg"
                  alt=""
                />
                <h3 className="congrats-text">{_t("congratulations")}</h3>
                <p className="congrats-desc">
                  {_t(
                    "yourRequestToYourGuarantorHasBeenSentOnceAcceptedYouWillBeRewardedWithFiftyPoints"
                  )}
                </p>
                {checkNextButton()}
                <button
                  className="w-100 btn btnoutline bg-none"
                  onClick={navigateBackHome}
                >
                  {_t("goBackHome")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TenantBankConnect;
