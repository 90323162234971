import { Modal } from 'react-bootstrap';
import useTranslator from '../hooks/useTranslator';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import AuthContext from '../context/auth/AuthContext';
import { haveValue } from '../helper/common';
import TenantContext from '../context/tenant/TenantContext';

const AlreadyGuarantorTo = (props) => {
  const { _t } = useTranslator();
  const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink']);
  const { alreadyGurantorTo, setAlreadyGurantorTo, gurantor_details } = props;
  const handleCloseModal = () => {
    setAlreadyGurantorTo(false);
    removeCookie('gurantorFor', { maxAge: 1800 });
  };

  return (
    <>
      <Modal
        show={alreadyGurantorTo}
        onHide={handleCloseModal}
        centered
        className="newmodalui asguarantor"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="already_guarantor">
            <img src="../../images/already_guarantor.svg" alt="" />
            <h4>
              {_t('asYouAreGuarantorOf')}{' '}
              <span className="orange_text">{`${gurantor_details?.fname} ${gurantor_details?.lname}`}</span>
              {_t('youCannotBeGuarantorOfAnotherTenant')}
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="already_guarantor_footer">
            <h6>{_t('wishToConnect')}</h6>
            <a href="mailto:info@skortorent.com " className="orange_text">
              info@skortorent.com{' '}
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AlreadyGuarantorTo;
