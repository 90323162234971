import { Button, Modal } from 'react-bootstrap';
import useTranslator from '../../hooks/useTranslator';

const RentMonthPaidModal = (props) => {
  const { handleClose, handleContinue, show } = props;
  const { _t } = useTranslator();
  return (
    <Modal className="modal-sm" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <h5 className="modal-main-heading">{_t('rentAlreadyPaid')}</h5>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          {_t('rentForSelectedMonthIsAlreadyPaidAreYouSureYouWantToContinue')}
        </p>
        <div className="d-flex align-items-center justify-content-around mt-4 btngroup gap-3 step-btns-group mobile-full-btn">
          <button
            className="btn btnoutline bg-none step-btn"
            onClick={handleClose}
          >
            {_t('goBack')}
          </button>
          <button className="btn btngreen" onClick={handleContinue}>
            {_t('continue')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RentMonthPaidModal;
