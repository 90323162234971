import { useNavigate } from 'react-router-dom';
import useTranslator from '../../hooks/useTranslator';
import { useContext } from 'react';
import AuthContext from '../../context/auth/AuthContext';
import { haveValue } from '../../helper/common';

const TenantAlreadyRenting = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const navigateRentStep = () => {
    if (haveValue(user?.assigned_property_id)) {
      navigate(`/accept/assigned/property/${user?.assigned_property_id}`);
    } else {
      navigate('/tenant/rentalStep');
    }
  };

  return (
    <>
      {user?.rent_paid ? (
        <></>
      ) : (
        <div className="pending-steps-wrapper already-renting">
          <h3 className="tiles-main-heading">
            {' '}
            {_t('ifYouAreAlreadyRenting')}{' '}
          </h3>
          <div className="d-flex tile-design mb-0 border-0">
            <div className="tile-content-wrapper">
              <img src="./images/payment-home-icon.svg" alt="" />
              <div className="tile-content">
                <h4 className="tile-heading">{_t('monthlyRentPayment')} </h4>
                <p className="tile-desc">
                  {_t(
                    'fantasticYouAreNowEarningRewardPointsAndBuildingYourRentalSkorEveryMonth'
                  )}{' '}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
              <div className="tile-points">
                <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                <span>100 {_t('points')}</span>
              </div>
              {console.log(user)}
              {user?.rent_paid ? (
                <button className="completed-btn">{_t('completed')}</button>
              ) : (
                <button className="incomplete-btn" onClick={navigateRentStep}>
                  {_t('complete')}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TenantAlreadyRenting;
