import React, { useEffect, useState } from 'react';
import { CChart } from '@coreui/react-chartjs';
import useTranslator from '../../hooks/useTranslator'

const GraphData = () => {

  const { _t } = useTranslator();

  useEffect(() => {
    changeDuration('weekly')
  }, [])

  const [duration, setDuration] = useState({
    labels: [],
    tenantData: [],
    landlordData: [],
    propertiesData:[],
  });

  const changeDuration = (value) => {
    if (value == "weekly") {
      setDuration({
        ...duration,
        labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
        tenantData: [40, 20, 12, 39, 10, 40, 39, 80, 40],
        landlordData: [50, 12, 28, 29, 7, 25, 12, 70, 60],
        propertiesData: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      })
    }
    if (value == "monthly") {
      setDuration({
        ...duration,
        labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
        tenantData: [40, 20, 12, 39, 10, 40, 39, 80, 40, 23, 28, 12, 40, 32, 20, 90],
        landlordData: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 10, 55, 12, 14, 10, 60],
        propertiesData: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 10, 55, 12, 14, 10, 60]
      })
    }
    if (value == "yearly") {
      setDuration({
        ...duration,
        labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
        tenantData: [40, 20, 12, 39, 10, 40, 39, 80, 40, 23, 28, 12, 40, 32, 20, 90],
        landlordData: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 10, 55, 12, 14, 10, 60],
        propertiesData: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 10, 55, 12, 14, 10, 60]
      })
    }
  }


  return (
    <>

      <button onClick={() => changeDuration('weekly')} className='btn' >{_t("weekly")}</button>
      <button onClick={() => changeDuration('monthly')} className='btn'>{_t("monthly")}</button>
      <button onClick={() => changeDuration('yearly')} className='btn'>{_t("yearly")}</button>


      <CChart
        type="line"

        data={{
          labels: duration?.labels,
          datasets: [
            {
              label: _t("tenants"),
              backgroundColor: "rgba(241, 60, 0, 0.8)",
              borderColor: "rgba(241, 60, 0, 0.8)",
              pointBackgroundColor: "rgba(241, 60, 0, 1)",
              pointBorderColor: "#fff",
              data: duration.tenantData,
            },
            {
              label: _t("landlords"),
              backgroundColor: "rgba(1, 1, 1, 0.84)",
              borderColor: "rgba(1, 1, 1, 0.84)",
              pointBackgroundColor: "rgba(1, 1, 1, 1)",
              pointBorderColor: "#fff",
              data: duration.landlordData
            },
          ],
        }}
      />

<CChart
  type="bar"
  data={{
    labels:duration?.labels,
    datasets: [
      {
        label: _t('properties'),
        backgroundColor: '#f87979',
        data:duration?.propertiesData,
      },
    ],
  }}
  labels={_t("months")}
/>

<CChart
  type="doughnut"
  radius="20%"
  spacing={10}
  data={{
    labels: [_t('tenants'), _t('landlords')],
    datasets: [
      {
        backgroundColor: ['#121212', '#DE4724'],
        data: [60, 40],
      },
    ],
  }}
/>


    </>
  )
}

export default GraphData;