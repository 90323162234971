import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OTPInput, { ResendOTP } from "otp-input-react";

import { FaArrowLeft } from "react-icons/fa6";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { BsLinkedin } from "react-icons/bs";
import { useCookies } from "react-cookie";

import { VscEye, VscEyeClosed } from "react-icons/vsc";
import i18next from "i18next";
import { haveValue } from "../helper/common";
import TenantContext from "../context/tenant/TenantContext";
import useTranslator from "../hooks/useTranslator";
import Loader from "../components/Loader";
import AuthContext from "../context/auth/AuthContext";

const EnterOtp = () => {
    const { _t } = useTranslator();

    const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink'])

    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const authContext = useContext(AuthContext);
    const {
        register,
        user,
        otppage,
        loadUser,
        setUserType,
        sendOTP,
        sendOTPES,
        resetPassword
    } = authContext;

    const queryParameters = new URLSearchParams(window.location.search);
    const tenantContext = useContext(TenantContext)

    const [showPassword, setShowPassword] = useState(false)
    const [passwordType, setPasswordType] = useState('password')
    const [showRePassword, setShowRePassword] = useState(false)
    const [rePasswordType, setRePasswordType] = useState('password')
    const [enterPassword, setEnterPassword] = useState(false)
    const [pwdErrorMsg, setpwdErrorMsg] = useState('')

    const [passwordDetails, setPasswordDetails] = useState({
        password: "",
        confirmPassword: ""
    })

    useEffect(() => {
        if (timer > 0) {
            console.log("timer started");
            let myInterval = setInterval(() => {
                if (timer > 0) {
                    timer = timer - 1;
                    setShowTimer(timer);
                }
                if (timer === 0) {
                    clearInterval(myInterval);
                    setShowResend(true);
                }
            }, 1000);
        }
        const usertype = localStorage.getItem("usertype")
        loadUser(usertype)
    }, [])

    let timer = 60;
    const [showTimer, setShowTimer] = useState();
    const [otp, setOtp] = useState({
        type: localStorage.getItem('usertype'),
        otp: 0,
    });

    const [OTP, setOTP] = useState("");
    const otpinputChange = (e) => {
        setOTP(e);
        setOtp({ ...otp, otp: e });
    };

    const [showResend, setShowResend] = useState(false);

    useEffect(() => {
        console.log(OTP);
        if (OTP.length == 6) {
            //alert(otp.otp,"6 characters reached");
            let user_type = localStorage.getItem("usertype");
            verifyOTP(user?._id, otp);
        }
    }, [OTP]);

    const sendOtpAgain = () => {

        if (localStorage.getItem("i18nextLng") == 'es')
            sendOTPES(user._id, otp.type);
        else
            sendOTP(user._id, otp.type);
        setShowResend(false);
    };

    const verifyOTP = async (userid, value) => {
        console.log(value);
        await axios
            .put(
                `${process.env.REACT_APP_SERVER_URL}/users/otp-verify/${userid}`,
                value,
                { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
            )
            .then((res) => {
                console.log(res);
                let serverResponse = res;
                let usertype = localStorage.getItem("usertype");
                if (serverResponse.data.status === "verified") {
                    snackbar.showMessage(_t("accountVerifiedSuccessfully"));
                    loadUser(usertype);
                    setEnterPassword(true)
                }
            })
            .catch((err) => {
                let serverResponse = err;
                console.log(serverResponse);
                toast.error(serverResponse.response.data.msg);
            });
    };

    let strongPassword = new RegExp(
        "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );

    const submitPassword = async (e) => {
        e.preventDefault()
        if (passwordDetails.password !== passwordDetails.confirmPassword) {
            setpwdErrorMsg(_t("passwordNotMatches"));
        } else if (!strongPassword.test(passwordDetails.password)) {
            setpwdErrorMsg(
                _t(
                    "pleaseIncludeUppercaseLowercaseAndSpecialCharactersInYourPassword"
                )
            );
        } else {
            const data = { ...passwordDetails, type: localStorage.getItem('usertype') }
            resetPassword(data, user?._id);
        }
    }
    const handleShowPassword = (val) => {
        if (val) {
            setPasswordType('text')
            setShowPassword(oldVal => !oldVal)
        }
        else {
            setPasswordType('password')
            setShowPassword(oldVal => !oldVal)
        }
    }
    const handleShowRePassword = (val) => {
        if (val) {
            setRePasswordType('text')
            setShowRePassword(oldVal => !oldVal)
        }
        else {
            setRePasswordType('password')
            setShowRePassword(oldVal => !oldVal)
        }
    }

    const handleValChange = (e) => {
        setpwdErrorMsg('')
        setPasswordDetails({ ...passwordDetails, [e.target.name]: e.target.value });
    }
    return (
        enterPassword ?
            <Fragment>
                < div className="Auth-wrapper-2" >
                    <div className="logobar">
                        <Container className="p-0">
                            <img src="../images/logo.svg" alt="logo" />
                        </Container>
                    </div>
                    <Container>
                        <div className="resend-text">
                            <h4>{_t("resetPassword")}</h4>
                            <p>{_t("passwordMustBeAtleastEightCharactersLongAndContainANumberLowercaseUppercaseAndASpecialCharacter")}</p>
                            <Form className="loginform" onSubmit={submitPassword}>
                                <div className="auth-form">
                                    <div className="forminput row50">
                                        <label className="forminput">{_t("newPassword")}</label>
                                        <div className="input-with-eye">
                                            <input
                                                type={passwordType}
                                                className="form-control user-auth"
                                                name="password"
                                                value={passwordDetails.password}
                                                onChange={handleValChange}
                                                required
                                                placeholder={_t("newPassword")}
                                            />
                                            {
                                                showPassword ?
                                                    <VscEye className="eye-icons-reset" onClick={e => handleShowPassword(false)} />
                                                    :
                                                    <VscEyeClosed className="eye-icons-reset" onClick={e => handleShowPassword(true)} />
                                            }
                                        </div>
                                    </div>
                                    <div className="forminput row50">
                                        <label className="forminput">{_t("reEnterNewPassword")}</label>
                                        <div className="input-with-eye">
                                            <input
                                                type={rePasswordType}
                                                className="form-control user-auth"
                                                name="confirmPassword"
                                                value={passwordDetails.confirmPassword}
                                                onChange={handleValChange}
                                                required
                                                placeholder={_t("reEnterNewPassword")}
                                            />

                                            {
                                                showRePassword ?
                                                    <VscEye className="eye-icons-reset" onClick={e => handleShowRePassword(false)} />
                                                    :
                                                    <VscEyeClosed className="eye-icons-reset" onClick={e => handleShowRePassword(true)} />
                                            }
                                        </div>

                                    </div>
                                    <div className="btngroup row100">
                                        {
                                            haveValue(pwdErrorMsg) &&
                                            <label className="forminput password_error">{pwdErrorMsg}</label>
                                        }
                                        <button
                                            className="btn btnorange"
                                            type="submit"
                                        >
                                            {_t("changePassword")}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Container>
                    <Container>
                    </Container>
                </div >
            </Fragment >
            :
            <Fragment>
                <div className="Auth-wrapper-2">
                    <div className="logobar">
                        <Container className="p-0">
                            <img src="../images/logo.svg" alt="logo" />
                        </Container>
                    </div>
                    <Container>
                        <div className="authbar-inner">
                            <div className="fullbar">
                                <div className="right-contentbar">
                                    {/* OTP Screen  */}
                                    <div className="resend-text">
                                        <h4 style={{ textTransform: "none !important" }}>{_t("otpSent")}{ }</h4>
                                        <p>{_t("pleaseEnterTheCodeSentToYourEmail")}</p>
                                        <Form className="loginform otp-Verification">
                                            <OTPInput
                                                className="forminput"
                                                value={OTP}
                                                onChange={otpinputChange}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                            />
                                        </Form>

                                        {!showResend && (
                                            <p className="timer">
                                                {" "}
                                                {_t("resendCode")} <span>{showTimer}s</span>
                                            </p>
                                        )}
                                        {showResend && (
                                            <Button className="btn-fill" onClick={sendOtpAgain}>
                                                {_t("resendOTP")}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <Container>
                    </Container>
                </div>
            </Fragment>
    );
};

export default EnterOtp;
