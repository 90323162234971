import axios from 'axios';
import React, { useContext, useState } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { TiTick } from 'react-icons/ti';
import { MdOutlineVerified } from 'react-icons/md';
import AuthContext from '../../context/auth/AuthContext';
import useTranslator from '../../hooks/useTranslator';
import { init } from 'onfido-sdk-ui';
import TenantContext from '../../context/tenant/TenantContext';
import { CiClock2 } from 'react-icons/ci';
import { GiCancel } from 'react-icons/gi';
import { haveValue } from '../../helper/common';

const Digitalprocess = ({ kycStatus, setKycStatus, onfido_data }) => {
  const [buttonDisable, setButtonDisable] = useState(false);

  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const { user, loadUserTenant } = authContext;
  const tenantContext = useContext(TenantContext);
  const { saveKycInfo, onfidoKyc } = tenantContext;

  const initiateOnfido = () => {
    setButtonDisable(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${user?._id}`)
      .then((res) => {
        console.log('ONFIDO WITH WORKFLOW= ', res.data);
        setButtonDisable(false);
        initiateKyc(res.data.sdk_data.token, res.data.workflow_data.id);
      })
      .catch((err) => {
        console.log('Error Onfido = ', err);
        setButtonDisable(false);
      });
  };
  const initiateKyc = (token, workFlowRunId) => {
    console.log('On Fido Token = ', token);
    setButtonDisable(true);
    let onfido = {};

    onfido = init({
      token: token,
      useModal: true,
      isModalOpen: true,
      language: localStorage.getItem('i18nextLng') == 'es' ? 'es' : 'en_US',
      containerId: 'onfido-mount',
      workflowRunId: `${workFlowRunId}`,
      onComplete: function (data) {
        console.log('everything is complete');
        console.log('onfido success response', data);
        loadUserTenant('tenant');
        setButtonDisable(false);
        setKycStatus('done');
        saveOnfidoKycResponse(data);
      },
      onError: function (err) {
        console.log('onfido error happend');
        console.log('ERROR OnFIDO = ', err);
        // setKycStatus("decline");
        setButtonDisable(false);
      },
      onUserExit: function (userExitCode) {
        console.log('user exitted', userExitCode);
        setButtonDisable(false);
      },
      onModalRequestClose: function () {
        // Update options with the state of the modal
        onfido.setOptions({ isModalOpen: false });
        setButtonDisable(false);
        console.log('modal closed of onfido');
      },
      //steps: ['welcome', 'poa', 'document', 'face', 'complete'],  //poa-proof of address
      steps: ['welcome', 'document', 'face', 'complete'],
    });
  };

  const saveOnfidoKycResponse = (kycData) => {
    onfidoKyc(user?._id, kycData)
      .then((res) => {
        console.log('promise resolved then response', res);
      })
      .catch((err) => {
        console.log('promise rejected err is ', err);
      });
  };

  const handleDoItLater = () => {
    setKycStatus('done');
  };

  return (
    <>
      {kycStatus == 'pending' && (
        <div className="vreify-box ">
          <div className="verify-detail">
            <FaUserAlt />
            <div className="detailin">
              <h4>{_t('useAnOriginalDocumentForDigitalVerification')}</h4>
              <p>{_t('weRecommendToUseSpanishIdIfAvailable')}</p>
            </div>
          </div>
          <div id="onfido-mount"></div>
          <div className="verify-btn">
            <button
              className="btn btnorange w-100"
              onClick={initiateOnfido}
              disabled={buttonDisable}
              type="button"
            >
              {_t('verifyIdentity')}
            </button>
            <h3 className="setNoInfoColor">
              <a href={undefined} onClick={handleDoItLater}>
                {_t('doItLater')}?
              </a>
            </h3>
          </div>
        </div>
      )}
      {/* {kycStatus == "done" && (
        <div className="vreify-box ">
          <div className="verify-detail">
            <div className="detailin">
              <h4>{_t("Use an original document for digital verification")}</h4>
              <p>{_t("We recommend to use Spanish ID, if available")}</p>
            </div>
          </div>
          <div className="status-bar inprogressbar">
            <h3>
              <CiClock2 />
              {_t("Verification In Progress")}
            </h3>
            <p>{_t("It May take a few minutes")}</p>
          </div>
          <div id="onfido-mount"></div>
        </div>
      )}
      {kycStatus == "verified" && (
        <div className="vreify-box ">
          <div className="verify-detail">
            <TiTick style={{ color: "#1b5e20" }} />
            <div className="detailin">
              <h4>{_t("Use an original document for digital verification")}</h4>
              <p>{_t("We recommend to use Spanish ID, if available")}</p>
            </div>
          </div>
          <div id="onfido-mount"></div>
        </div>
      )}
      {kycStatus == "decline" && (
        <div className="vreify-box ">
          <div className="verify-detail ">
            <TiTick style={{ color: "#1b5e20" }} />
            <div className="detailin">
              <h4>{_t("Use an original document for digital verification")}</h4>
              <p>{_t("We recommend to use Spanish ID, if available")}</p>
            </div>
          </div>
          <div className="status-bar declinebar">
            <h4>
              <GiCancel />
              {_t("Verification Declined")}
            </h4>
            <h6>
              {
                haveValue(onfido_data?.onfido_error) && haveValue(onfido_data?.onfido_error[0]) &&
                onfido_data?.onfido_error[0]
              }
            </h6>
            <p>{_t("Please try again")}</p>
            <div className="verify-btn">
              <button
                className="btn btnorange w-100"
                onClick={initiateOnfido}
                disabled={buttonDisable}
                type="button"
              >
                {_t("Verify Identity")}
              </button>
            </div>
          </div>
          <div id="onfido-mount"></div>
        </div>
      )} */}
    </>
  );
};

export default Digitalprocess;
