import constants from '../../Utils/constants';
import { haveValue } from '../../helper/common';
import { FaRegHourglass } from 'react-icons/fa';
import useTranslator from '../../hooks/useTranslator';
import { TiTick } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

const TenantPaymentList = (props) => {
  const { _t } = useTranslator();
  const { status, data } = props;
  const navigate = useNavigate();

  const handleOpenTransactions = (id) => {
    navigate(`/payment/details/${id}`);
  };

  console.log(data);
  return (
    <>
      <div
        className="blockbookpayment"
        onClick={(e) => handleOpenTransactions(data?._id)}
      >
        <div className="lleftblock">
          <h5>
            {' '}
            {`${
              haveValue(data?.tenant_id?.fname) ? data?.tenant_id?.fname : ''
            } ${
              haveValue(data?.tenant_id?.lname) ? data?.tenant_id?.lname : ''
            }`}{' '}
          </h5>
          <p>
            {' '}
            {`${data?.property_id?.street} - ${data?.property_id?.street_number}, ${data?.property_id?.floor_number}-${data?.property_id?.flat_number}`}{' '}
          </p>
        </div>
        <div className="rytblock">
          {status == constants.TENANT_PENDING && (
            <>
              <FaRegHourglass /> {_t('waitingForTenant')}
            </>
          )}
          {status == constants.AWARDED && (
            <>
              <TiTick /> {_t('inPayment')}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TenantPaymentList;
