import { Modal } from 'react-bootstrap';
import useTranslator from '../hooks/useTranslator';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import AuthContext from '../context/auth/AuthContext';
import { haveValue } from '../helper/common';
import TenantContext from '../context/tenant/TenantContext';

const SomeoneGuarantor = (props) => {
  const { _t } = useTranslator();
  const {
    alreadyGurantorOf,
    setAlreadyGurantorOf,
    gurantorPresent,
    setGurantorPresent,
  } = props;
  const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink']);

  const handleHideModal = () => {
    setAlreadyGurantorOf(false);
    setGurantorPresent(false);
    removeCookie('gurantorFor', { maxAge: 1800 });
  };

  return (
    <>
      <Modal
        show={alreadyGurantorOf}
        onHide={handleHideModal}
        centered
        className="newmodalui asguarantor someonegaurentor"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="already_guarantor_someone">
            {/* <img src="../../images/have_guarantor.svg" alt="" /> */}
            <h4>{_t('oops')}</h4>
            <h5>
              {_t(
                'looksLikeTheGuarantorYouHaveSelectedIsAlreadyAGuarantorForSomeoneElse'
              )}
            </h5>
          </div>
          <div className="already_guarantor_footer">
            <p>
              {_t(
                'pleaseReachOutToThemToSelectOneOrChooseSomeoneElseInCaseOfAnyQuestionsOrDoubtsPleaseWriteTo'
              )}
               <a href="mailto:info@skortorent.com">info@skortorent.com</a> 
              {_t('orReadOurFaqs')}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SomeoneGuarantor;
