import React from "react";
import { Link } from "react-router-dom";
import useTranslator from "../hooks/useTranslator";

const NotFoundPage = () => {
  const { _t } = useTranslator();

  return (
    <>
      <div className="main-wapper height-auto">
        <div className="container">
          <h1>{_t("thePageYouAreLookingIsNotFound")}</h1>
          {/* <Link to='/home'>{_t("Home")}</Link> */}
          <Link to="/tenant">{_t("home")}</Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
