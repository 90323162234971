import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import AccountLoading from '../../../components/AccountLoading';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTranslator from '../../../hooks/useTranslator';

const Finance = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const code = queryParameters.get("code");
  console.log(code);


  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    await function1()   //await lagane se jab tk ye function complete nhi tab tk function2 run nhi hoga
      .then(res => {
        setLoading(false);
        console.log("promise resolved", res)  //agar resolve kuchh bhi rha to then block me aa jayes as promise resolved
        //agar hm change me then resolve me err hi bheje but here it will be in then block
      })
      .catch(err => {
        setLoading(false);
        console.log("promise rejected", err) // agar hm reject bhejte hain then is block me aayega agar hm successfull api call me response aane 
        //ke baad bhi chahe to reject bhejenge to yahan aayega
      })
    function2();
  }, [])



  const function1 = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_SERVER_URL}/properties/`,{headers:{"Accept-Language" : localStorage.getItem('i18nextLng')}})
        .then(res => {
          console.log("details fetched", res.data);
          //resolve me true ya kucch bhi object bhej skte hain whan wo then block me aa jayega promise resolved me
          //return resolve(true);
          return resolve(res.data);
          //return reject(true);  //hm chanhe to reject bhi bhej skte hai wo rejected block me chala jayega

        })
        .catch(err => {
          let err_res = err;
          console.log("Error happened", err_res);
          //same here hm chahe to resolve me err response bhejente to whan promise resolve me chala jayega
          //if reject me bhejenge to promise rejected me chala jayega
          //return resolve(false); 
          //return reject(true); 
          return reject(err_res);
          //return resolve(err_res); 
        })
    })
  }

  const function2 = () => {
    console.log("normal function2")
  }


  //payment info


  let options = {
    key:"YOUR_KEY_ID", // Enter the Key ID generated from the Dashboard
    amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "Acme Corp", //your business name
    description : "Test Transaction",
    image: "https://example.com/your_logo",
    order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler : function (response){
      alert(response.razorpay_payment_id);
      alert(response.razorpay_order_id);
      alert(response.razorpay_signature);
  },
    prefill:{
      name: "Gaurav Kumar", //your customer's name
    email: "gaurav.kumar@example.com",
    contact: "9000090000"
  },
    notes: {
      address : "Razorpay Corporate Office"
  },
    theme: {
      color: "#3399cc"
  }
}



  // let rzp1 = new Razorpay(options);

  // rzp1.on('payment.failed', function (response) {
  //   alert(response.error.code);
  //   alert(response.error.description);
  //   alert(response.error.source);
  //   alert(response.error.step);
  //   alert(response.error.reason);
  //   alert(response.error.metadata.order_id);
  //   alert(response.error.metadata.payment_id);
  // });


  const makePayment = (e) =>{
  //   rzp1.open();
  //   e.preventDefault();
  }





  if (loading) {
    return (
      <AccountLoading />
    )
  }

  return (
    <>
      <div className='margin-div'></div>
      <Link to='/home'>{_t("takeMeHome")}</Link>

      <button id="rzp-button1" onClick={makePayment}>{_t("pay")}</button>
    
     
      
      
    </>

  )
}

export default Finance