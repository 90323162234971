import React, { useReducer, useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';
import { useNavigate } from 'react-router-dom';

import LandLordContext from '../landlord/LandLordContext';
import useTranslator from '../../hooks/useTranslator';

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  CARD_DATA,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  TOGGLE_LOGIN,
  TOGGLE_LOADING,
  TOGGLE_BANK_LOADING,
  TOGGLE_ALGOAN_LOADING,
  SHOW_ALERT,
  REMOVE_ALERT,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  USER_DATA_LOADED,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
  SET_USER_TYPE,
  SETOTP_PAGE,
  USER_VERIFIED,
  NOTIFICATION_LOADED,
  LOGGED_IN_TRUE,
  USER_UNVERIFIED,
} from '../types';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { haveValue } from '../../helper/common';
import constants from '../../Utils/constants';

const AuthState = (props) => {
  const navigate = useNavigate();
  const { _t } = useTranslator();

  const initialState = {
    loading: false,
    alertMsg: '',
    msg: '',
    user: null,
    isLoggedIn: false,
    errorMessage: '',
    userType: 'tenant',
    otppage: 0,
    verified: false,
    notifications: [],
    bankLoading: false,
    algoanLoading: false,
  };

  let token = localStorage.getItem('token');
  let is_verified = localStorage.getItem('is_verified');
  if (token && is_verified && is_verified == true) {
    initialState.isLoggedIn = true;
    initialState.verified = true;
  }

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // login
  const login = (user) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/users/login`, user, {
        headers: { 'Accept-Language': localStorage.getItem('i18nextLng') },
      })
      .then((res) => {
        let serverResponse = res;
        console.log(serverResponse.data);
        let usertype = localStorage.getItem('usertype');
        if (serverResponse.data.token) {
          localStorage.setItem('token', serverResponse.data.token);
          localStorage.setItem('is_verified', true);

          dispatch({
            type: USER_VERIFIED,
          });
          dispatch({
            type: LOGIN_SUCCESS,
          });
          loadUser(usertype);
        } else {
          alert(_t("loginFailed"));
        }
      })
      .catch((e) => {
        let emsg = e;
        console.log(emsg.response.data);
        alert(emsg.response.data.message);
      });
  };
  // register
  const register = async (userData) => {
    console.log(userData);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/users/signup`, userData, {
        headers: { 'Accept-Language': localStorage.getItem('i18nextLng') },
      })
      .then((res) => {
        // console.log(res.data);
        let serverResponse = res;
        console.log(serverResponse.data);
        if (serverResponse.data.token) {
          localStorage.setItem('token', serverResponse.data.token);
          localStorage.setItem('saveduserid', serverResponse.data.user._id);
          let usertype = localStorage.getItem('usertype');

          if (localStorage.getItem('i18nextLng') == 'es')
            sendOTPES(serverResponse.data.user._id, usertype);
          else sendOTP(serverResponse.data.user._id, usertype);

          dispatch({
            type: REGISTER_SUCCESS,
            payload: serverResponse.data,
          });
          //toast.success("An confirmation Email sent to your Email", { autoClose: 10000, })
          loadUser(usertype);
        } else {
          console.log('REMOVED FROM REGISTER FUNCTION');
          localStorage.removeItem('token');
          dispatch({
            type: REGISTER_FAIL,
            payload: serverResponse.data,
          });
          toast.error(serverResponse.data);
        }
      })
      .catch((err) => {
        localStorage.removeItem('token');
        let emsg = err;
        console.log(emsg.response.data);
        toast.error(emsg.response.data.errormsg);
        dispatch({
          type: REGISTER_FAIL,
          payload: emsg.response.data.errormsg,
        });
      });
  };
  const forgotPassword = async (userData) => {
    const res = await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/users/forgotPassword`,
        userData,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then(async (res) => {
        console.log(res.data);
        if (res.data.found) {
          let usertype = localStorage.getItem('usertype');

          localStorage.setItem('token', res.data.token);
          localStorage.setItem('saveduserid', res.data.user._id);
          localStorage.setItem('external_customer_id', res.data.user._id);
          dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data.user,
          });
          dispatch({
            type: SETOTP_PAGE,
            payload: 0,
          });
          if (localStorage.getItem('i18nextLng') == 'es')
            await sendOTPES(res.data.user._id, usertype);
          else await sendOTP(res.data.user._id, usertype);
          navigate('/enter-otp');
        }
      })
      .catch((err) => {
        let emsg = err;
        console.log(emsg.response.data);
        toast.error(emsg.response.data.errormsg);
      });
    if (haveValue(res)) {
      return res;
    }
  };
  const changePassword = async (passData, userid) => {
    const res = await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/users/newpassword/${userid}`,
        passData,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then(async (res) => {
        console.log('password changed');
        return true;
      })
      .catch((err) => {
        let emsg = err;
        console.log(emsg.response.data);
        toast.error(emsg.response.data.errormsg);
        return false;
      });
    return res;
  };

  const checkIeDetails = (userDetails) => {
    var check = true;

    if (!haveValue(userDetails?.ie_details_id)) {
      check = false;
      console.log('Failing in no entry');
    }
    if (userDetails?.ie_student_id != userDetails?.ie_details_id?.student_id) {
      check = false;
      console.log('Failing in studentid');
    }
    return check;
  };

  const loadUser = async (usertype) => {
    setLoading(true);
    setUserType(usertype);
    // if (usertype == 'tenant') {
    const userid = localStorage.getItem('saveduserid');
    loadMyNotifications(userid, usertype);
    // }
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/users/protect?type=${usertype}`,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('i18nextLng'),
          },
        }
      )
      .then((res) => {
        let serverResponse = res.data;
        localStorage.setItem('saveduserid', serverResponse._id);
        localStorage.setItem('external_customer_id', serverResponse._id);
        console.log(res.data, 'check the res.data here');
        if (haveValue(serverResponse?.ie_details_id)) {
          res.data.ie_verified = checkIeDetails(serverResponse);
        }
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      })
      .catch((err) => {
        setLoading(false);
        let serverResponse = err;
        console.log('REMOVED FROM loadUser FUNCTION');
        localStorage.removeItem('is_verified');
        console.log(serverResponse);
        // logout();
        toast.error(serverResponse.response.data.msg);
      });
  };
  const loadUserTenant = async (usertype) => {
    setUserType(usertype);

    // loadMyNotifications(userid, usertype)
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/users/protect?type=${usertype}`,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('i18nextLng'),
          },
        }
      )
      .then((res) => {
        let serverResponse = res.data;
        localStorage.setItem('saveduserid', serverResponse._id);
        localStorage.setItem('external_customer_id', serverResponse._id);

        if (haveValue(serverResponse?.ie_details_id)) {
          res.data.ie_verified = checkIeDetails(serverResponse);
        }
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
        const user_data = res.data;
        if (
          +user_data?.myScore == 100 &&
          user_data?.user_type == constants.MACC &&
          !user_data?.macc_landlord_mail_sent
        ) {
          axios.post(
            `${process.env.REACT_APP_SERVER_URL}/tenants/send-macc-email/${serverResponse._id}/${process.env.REACT_APP_MACC_LANDLORED_ID}`
          );
        }
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse, 'Server ERROR HERE!');
        toast.error(serverResponse.response.data.msg);
      });
  };

  const getUserDetails = async (usertype, userId) => {
    const res_data = await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/users/get-single/${userId}?type=${usertype}`,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
    return res_data;
  };
  const setUserType = (value) => {
    localStorage.removeItem('usertype');
    localStorage.setItem('usertype', value);
    dispatch({
      type: SET_USER_TYPE,
      payload: value,
    });
  };

  const setLoggedInUser = () => {
    dispatch({
      type: LOGGED_IN_TRUE,
    });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('saveduserid');
    localStorage.removeItem('is_verified');

    // console.log("user is logged out")
    dispatch({
      type: USER_UNVERIFIED,
    });
    dispatch({
      type: LOGOUT,
    });
    toast.success(_t("loggedOutSuccessfully"));
  };
  const toggleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('saveduserid');
    localStorage.removeItem('is_verified');

    // console.log("user is logged out")
    dispatch({
      type: USER_UNVERIFIED,
    });
    dispatch({
      type: LOGOUT,
    });
  };
  const resetPassword = async (value, userid) => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/users/changepassword/${userid}`,
        value,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        toast.success(serverResponse.data.msg);
        navigate('/userrole');
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.msg);
      });
  };
  const changeImage = async (value, userid) => {
    console.log('data from auth state', value);
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/users/upload-image/${userid}`,
        value,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        if (res.data.msg == 'Image updated') {
          toast.success(res.data.msg);
        } else {
          toast.error(_t("imageUploadFailed"));
        }
      })
      .catch((err) => {
        console.log(err);
        let serverResponse = err;
        //toast.error(serverResponse.response.data.error);
      });
  };
  const toggleLogin = (value) => {
    console.log('set toggle login function runs');
    dispatch({
      type: TOGGLE_LOGIN,
      payload: value,
    });
  };
  const setLoading = (value) => {
    dispatch({
      type: TOGGLE_LOADING,
      payload: value,
    });
  };
  const setBankLoading = (value) => {
    dispatch({
      type: TOGGLE_BANK_LOADING,
      payload: value,
    });
  };
  const setAlgoanLoading = (value) => {
    dispatch({
      type: TOGGLE_ALGOAN_LOADING,
      payload: value,
    });
  };
  const setotpPage = (value) => {
    dispatch({
      type: SETOTP_PAGE,
      payload: value,
    });
  };
  const sendOTP = async (userid, usertype) => {
    console.log(userid);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/users/otp-verify/${userid}?type=${usertype}`,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        //toast.success("Please Enter Your OTP");
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.msg);
      });
  };
  const sendOTPES = async (userid, usertype) => {
    console.log(userid);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/users/otp-verify/es/${userid}?type=${usertype}`,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        //toast.success("Please Enter Your OTP");
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.msg);
      });
  };
  const verifyOTP = async (userid, value) => {
    console.log(userid);
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/users/otp-verify/${userid}`,
        value,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        let usertype = localStorage.getItem('usertype');
        localStorage.setItem('is_verified', true);
        if (serverResponse.data.status == 'verified') {
          //toast.success("Email verified Successfully");
          loadUser(usertype);
          dispatch({
            type: USER_VERIFIED,
          });
        }
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.msg);
      });
  };
  //notifications
  const loadMyNotifications = async (userid, usertype, lastReadAt = null) => {
    await axios
      .get(
        `${
          process.env.REACT_APP_SERVER_URL
        }/notifications/${userid}?type=${usertype}${
          haveValue(lastReadAt) ? '&lastReadAt=true' : ''
        }`,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        let serverResponse = res.data.notifications;
        serverResponse = serverResponse.reverse();

        dispatch({
          type: NOTIFICATION_LOADED,
          payload: serverResponse,
        });
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.msg);
      });
  };

  const updateProfile = async (id, profileData) => {
    console.log('Update Profile = ', updateProfile);
    setLoading(true);
    try {
      console.log(
        'in context function check url - ',
        `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`
      );
      console.log(profileData);
      await axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`,
          profileData,
          { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
        )
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          toast.success(_t("profileSuccessfullyUpdated"));
          let usertype = localStorage.getItem("usertype");
          loadUser(usertype);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          let serverResponse = err;
          console.log(serverResponse);
          toast.error(serverResponse.response.data.msg);
        });
    } catch (err) {
      console.log('Some issue while updating profile (AuthState.js) - '.err);
      let serverResponse = err;
      console.log('check the server response - ', serverResponse);
      toast.error(serverResponse.response.data.msg);
    }
    setLoading(false);
  };
  const addCard = async (cardData) => {
    setLoading(true);
    try {
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users/saveCard`, cardData, {
          headers: { 'Accept-Language': localStorage.getItem('i18nextLng') },
        })
        .then((res) => {
          console.log(res.data, 'IN ADDCARD STATE');
          dispatch({
            type: USER_LOADED,
            payload: res.data.user,
          });
          setLoading(false);
          toast.success(_t("cardAddedSuccessfully"));
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          let serverResponse = err;
          console.log(serverResponse);
          toast.error(serverResponse.response.data.msg);
        });
    } catch (err) {
      console.log('Some issue while adding card - '.err);
      let serverResponse = err;
      console.log('check the server response - ', serverResponse);
      toast.error(serverResponse.response.data.msg);
    }
    setLoading(false);
  };

  const fetchCardDetails = async (cardId) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/users/getCardDetails/${cardId}`,
          { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
        )
        .then((res) => {
          dispatch({
            type: CARD_DATA,
            payload: res.data.cardData,
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      console.log('Some issue while adding card - '.err);
    }
  };

  const notificationSeen = (notificationid) => {
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/notifications/single-notification/${notificationid}`,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeUserLng = () => {
    const sendData = {
      current_lng: localStorage.getItem('i18nextLng'),
      id: localStorage.getItem('saveduserid'),
      role: localStorage.getItem('usertype'),
    };

    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/users/changeUserLng`,
      sendData,
      { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
    );
  };

  const updateUserInfo = async (formData = {}) => {
    try {
      const data = {
        formData,
        id: localStorage.getItem('saveduserid'),
        type: localStorage.getItem('usertype'),
      };
      const result = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/users/update/info`,
        data,
        { headers: { 'Accept-Language': localStorage.getItem('i18nextLng') } }
      );
      return result.data.user;
    } catch (error) {
      toast.error(_t("errorWhileUpdatingInfo"));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading: state.loading,
        showAlert: state.showAlert,
        msg: state.msg,
        user: state.user,
        isLoggedIn: state.isLoggedIn,
        errorMessage: state.errorMessage,
        alertMsg: state.alertMsg,
        users: state.users,
        userType: state.userType,
        otppage: state.otppage,
        verified: state.verified,
        notifications: state.notifications,
        cardData: state.cardData,
        bankLoading: state.bankLoading,
        algoanLoading: state.algoanLoading,
        register,
        setotpPage,
        sendOTP,
        sendOTPES,
        verifyOTP,
        loadUser,
        login,
        logout,
        toggleLogout,
        changePassword,
        changeImage,
        setLoggedInUser,
        setUserType,
        fetchCardDetails,
        toggleLogin,
        setLoading,
        loadMyNotifications,
        updateProfile,
        addCard,
        notificationSeen,
        loadUserTenant,
        changeUserLng,
        getUserDetails,
        forgotPassword,
        resetPassword,
        setBankLoading,
        setAlgoanLoading,
        updateUserInfo,
        checkIeDetails,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
