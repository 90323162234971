import React, { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bellicon from "../../assets/img/bell.png";
import eyeicon from "../../assets/img/eye.png";
import logouticon from "../../assets/img/logout.png";
import useTranslator from "../../hooks/useTranslator";
import { haveValue } from "../../helper/common";
import i18next from "i18next";
import { MdInfoOutline } from "react-icons/md";
import { Modal } from "react-bootstrap";

const TenantNavbar = () => {
  const { _t } = useTranslator();
  const location = useLocation();

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng").toLowerCase() || "en-us"
  );

  const [showModal, setShowModal] = useState(false);

  const [ureadCount, setUnreadCount] = useState(0);

  const authContext = useContext(AuthContext);
  const { isLoggedIn, user, notifications, userType, logout, changeUserLng } =
    authContext;
  const navigate = useNavigate();

  const takeMeToProfile = () => {
    navigate(`/${userType}-profile`);
  };

  useEffect(() => {
    var count = 0;
    if (notifications && notifications.length > 0) {
      notifications.forEach((val) => {
        if (val.marked_read == false) {
          count++;
        } else {
          return;
        }
      });
      setUnreadCount(count);
    }
  }, [notifications]);

  useEffect(() => {
    if (haveValue(user?.lang)) {
      const e = { target: { value: user?.lang } };

      handleLanguageChange(e);
    }
  }, [user]);

  const handleLanguageChange = async (e) => {
    var langVal = e.target.value;
    await i18next.changeLanguage(langVal);
    await changeUserLng();
    setCurrentLang(langVal);
  };

  return (
    <>
      <div id="header" className="head-cus">
        <div className="header-left header-tenant-left float-left custom-header">
          <div className="firstaside">
            <h5>
              <img
                src="/images/Skor_dark_green_logo.svg"
                id="skor_navbar_logo"
                alt=""
              />
            </h5>
          </div>
          <div className="second-aisde">
            <div class="menu-togglebar">
              <label class="customradiobtn">
                <input
                  type="radio"
                  onClick={handleLanguageChange}
                  checked={
                    currentLang == "en-us" || currentLang == "en-US"
                      ? "checked"
                      : ""
                  }
                  name="radio"
                  value={"en-us"}
                />
                <span class="checkmark1">{_t("en")}</span>
              </label>
              <label class="customradiobtn">
                <input
                  type="radio"
                  onClick={handleLanguageChange}
                  checked={currentLang == "es" ? "checked" : ""}
                  name="radio"
                  value={"es"}
                />
                <span class="checkmark1">{_t("es")}</span>
              </label>
            </div>
            <div className="slideryt">
              <div className="re-div">
                <div className="dropdown admin-btn">
                  <Link data-bs-toggle="dropdown">
                    <div className="admin-name">
                      {userType == "landlord" ? (
                        <>
                          {user?.fname_companyName &&
                            user?.fname_companyName.charAt(0).toUpperCase()}
                          {user?.lname && user?.lname.charAt(0).toUpperCase()}
                        </>
                      ) : (
                        <>
                          {user?.fname && user?.fname.charAt(0).toUpperCase()}
                          {user?.lname && user?.lname.charAt(0).toUpperCase()}
                        </>
                      )}
                    </div>

                    <ul className="dropdown-menu">
                      <div
                        className="hover-dropdown"
                        style={{ display: "block" }}
                      >
                        <Link onClick={takeMeToProfile}>
                          <img src={eyeicon} alt="" /> {_t("viewProfile")}
                        </Link>
                        <Link
                          onClick={(e) => setShowModal(true)}
                          className="pb-0"
                        >
                          <img src={logouticon} alt="" /> {_t("logout")}
                        </Link>
                      </div>
                    </ul>
                  </Link>
                </div>
              </div>
              <div>
                <Link to="/notification">
                  <span className="badge text-white">{ureadCount}</span>
                  <img src={bellicon} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sub-header-links" className="">
        {/* <div className="row"> */}
        <div className=" sub-head-links-wrapper">
          <div
            className={`sub-link ${location.pathname == "/tenant" && "active"}`}
          >
            <Link to="/tenant">{_t("myDashBoard")}</Link>
          </div>
          <div
            className={`sub-link ${
              location.pathname == "/profile-shared" && "active"
            } ${
              user?.onfido_kyc_success || user?.kyc_success
                ? ""
                : "disabled_nav"
            }`}
          >
            <Link to="/profile-shared" className="">
              {_t("profileShared")}
            </Link>
          </div>
          <div
            className={`sub-link ${
              location.pathname == "/tenant/review/rental" && "active"
            } ${user?.rent_paid ? "" : "disabled_nav"}`}
          >
            <Link to="/tenant/review/rental" className="">
              {_t("rentDetails")}
            </Link>
          </div>
        </div>
        {/* </div> */}
      </div>

      <Modal show={showModal} centered className="newmodalui asguarantor">
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="forminput row100">
            {_t("areYouSureYouWantToLogout")}
          </div>
          <div className="btngroup row100 gridbar">
            <button
              className="btn btnoutline cancel-btn"
              type="button"
              onClick={(e) => setShowModal(false)}
            >
              {_t("cancel")}
            </button>
            <button className="btn btnorange" type="button" onClick={logout}>
              {_t("logout")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TenantNavbar;
