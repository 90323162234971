import { useState, useEffect, useRef } from 'react';

const useCountdown = (initialSeconds) => {
    const [seconds, setSeconds] = useState(initialSeconds);
    const timerRef = useRef(null);

    const startCountdown = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current); // Clear any previous interval
        }

        timerRef.current = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds <= 1) {
                    clearInterval(timerRef.current); // Stop the timer
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);
    };

    const resetCountdown = () => {
        clearInterval(timerRef.current);
        setSeconds(initialSeconds);
    };

    useEffect(() => {
        return () => clearInterval(timerRef.current); // Cleanup on unmount
    }, []);

    return { seconds, startCountdown, resetCountdown };
};

export default useCountdown;