import React, { useContext } from 'react';
import AuthContext from '../context/auth/AuthContext';
import LandLordContext from '../context/landlord/LandLordContext';
import useTranslator from '../hooks/useTranslator';

const Error = (props) => {
  const authContext = useContext(AuthContext);
  const { _t } = useTranslator();

  const { user } = authContext;

  const landLordContext = useContext(LandLordContext);
  const { setErrorResolve, landlord_error } = landLordContext;

  return (
    <>
      <h3>{_t('somethingWentWrong')}</h3>
      <h2>{landlord_error}</h2>
      <button
        className="btn btn-outline-dark"
        onClick={() => setErrorResolve()}
      >
        {_t('tryAgain')}
      </button>
    </>
  );
};

export default Error;
