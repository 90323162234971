import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AssignPropertyStart from "../../../components/Landlord/AssignPropertyStart";
import PropertyRentDetails from "../../../components/Landlord/PropertyRentDetails";
import LandLordContext from "../../../context/landlord/LandLordContext";
import { haveValue } from "../../../helper/common";
import ReviewAssignProperty from "../../../components/Landlord/ReviewAssignProperty";
import { toast } from "react-toastify";
import useTranslator from "../../../hooks/useTranslator";

const AssignProperty = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const { property_id } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const shared_profile_id = queryParameters.get("sharedProfile");
  const edit_id = queryParameters.get("editId");
  const paramStep = queryParameters.get("step");
  const landLordContext = useContext(LandLordContext);
  const { getPropertyDetails, getSharedProfile, fetchAssignedDetails } =
    landLordContext;
  const initialState = {
    edit_id: null,
    property_id: property_id,
    shared_profile_id: shared_profile_id,
    payment_start: "",
    lease_end: "",
    shared_household: "no",
    rent_amount: "",
    deposit_amount: "",
    monthly_sub_amount: "",
  };

  const [property, setProperty] = useState(null);
  const [sharedProfile, setSharedProfile] = useState(null);
  const [currStep, setCurrStep] = useState(
    haveValue(paramStep) ? +paramStep : 0
  );
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    getPropertyDetails(property_id)
      .then((res) => {
        setProperty(res.properties);
      })
      .catch((err) => {
        console.log(err);
      });
    getSharedProfile(shared_profile_id)
      .then((res) => {
        setSharedProfile(res.singleSharedProfile);
      })
      .catch((err) => {
        console.log(err);
      });
    if (haveValue(edit_id)) {
      fetchAssignedDetails(edit_id)
        .then((res) => {
          const data = res.result;
          console.log(res.result);
          const temp = {
            edit_id: data?._id,
            property_id: property_id,
            shared_profile_id: shared_profile_id,
            payment_start: data?.payment_start.slice(0, 10),
            lease_end: data?.lease_end?.slice(0, 10),
            shared_household: data?.shared_household,
            rent_amount: data?.rent_amount,
            deposit_amount: data?.deposit_amount,
          };
          setFormData(temp);
        })
        .catch((err) => {
          console.log("ERROR FETCHDETAILS", err);
          toast.error(_t("errorWhileFetchingDetails"));
        });
    }
  }, []);

  const handleNextStep = () => {
    const temp = currStep + 1;
    navigate(
      `/assignProperty/${property_id}?sharedProfile=${shared_profile_id}&step=${temp}`
    );
    setCurrStep((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    const temp = currStep - 1;
    navigate(
      `/assignProperty/${property_id}?sharedProfile=${shared_profile_id}&step=${temp}`
    );
    setCurrStep((prev) => prev - 1);
  };

  return (
    <>
      {currStep == 0 && <AssignPropertyStart handleNextStep={handleNextStep} />}
      {currStep == 1 && (
        <PropertyRentDetails
          handleNextStep={handleNextStep}
          property={property}
          sharedProfile={sharedProfile}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {currStep == 2 && (
        <ReviewAssignProperty
          property={property}
          sharedProfile={sharedProfile}
          formData={formData}
          handlePrevStep={handlePrevStep}
        />
      )}
    </>
  );
};

export default AssignProperty;
