import { useContext } from 'react';
import useTranslator from '../../hooks/useTranslator';
import AuthContext from '../../context/auth/AuthContext';
import { haveValue } from '../../helper/common';
import { useNavigate } from 'react-router-dom';

const TenantDetails = () => {
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const navigate = useNavigate();

  return (
    <>
      <div className=" d-none d-md-flex  tenant-info-wrapper align-items-center justify-content-between">
        <div className="d-flex align-items-center tenant-info">
          <div className="tenant-badge-name">
            <>
              {user?.fname && user?.fname.charAt(0).toUpperCase()}
              {user?.lname && user?.lname.charAt(0).toUpperCase()}
            </>
          </div>
          <div>
            <div className="tenant-details">
              <h4 className="tenant-name">
                {user?.fname} {user?.lname}
              </h4>
              <div className="d-flex gap-2">
                <div
                  className={`tenant-status ${
                    user?.onfido_kyc_success
                      ? 'complete'
                      : user?.onfido_user_kyc?.onfido_workflow_res?.status ==
                        'declined'
                      ? 'failed'
                      : 'pending'
                  }`}
                >
                  {user?.onfido_kyc_success ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <circle cx="11" cy="11" r="11" fill="white" />
                        <path
                          d="M15.2308 8.32699L9.42863 14.3845L6.76929 11.6081L7.45105 10.8964L9.42863 12.9559L14.5491 7.61523L15.2308 8.32699Z"
                          fill="#253837"
                          stroke="#253837"
                          stroke-width="0.6"
                        />
                      </svg>{' '}
                      <span>{_t('verified')}</span>
                    </>
                  ) : user?.onfido_user_kyc?.onfido_workflow_res?.status ==
                    'declined' ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <circle cx="11" cy="11" r="11" fill="white" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.8485 7.88311C14.8965 7.83513 14.9346 7.77816 14.9607 7.71545C14.9867 7.65274 15.0001 7.58552 15.0001 7.51763C15.0002 7.44974 14.9868 7.3825 14.9609 7.31976C14.935 7.25702 14.8969 7.2 14.8489 7.15197C14.801 7.10393 14.744 7.06581 14.6813 7.03979C14.6186 7.01377 14.5513 7.00036 14.4835 7.00032C14.4156 7.00028 14.3483 7.01361 14.2856 7.03955C14.2228 7.06549 14.1658 7.10354 14.1178 7.15152L11 10.2693L7.88311 7.15152C7.78609 7.0545 7.65451 7 7.51731 7C7.38011 7 7.24853 7.0545 7.15152 7.15152C7.0545 7.24853 7 7.38011 7 7.51731C7 7.65451 7.0545 7.78609 7.15152 7.88311L10.2693 11L7.15152 14.1169C7.10348 14.1649 7.06538 14.222 7.03938 14.2847C7.01338 14.3475 7 14.4148 7 14.4827C7 14.5506 7.01338 14.6179 7.03938 14.6807C7.06538 14.7434 7.10348 14.8004 7.15152 14.8485C7.24853 14.9455 7.38011 15 7.51731 15C7.58525 15 7.65252 14.9866 7.71528 14.9606C7.77804 14.9346 7.83507 14.8965 7.88311 14.8485L11 11.7307L14.1178 14.8485C14.2148 14.9454 14.3463 14.9998 14.4835 14.9997C14.6206 14.9996 14.752 14.945 14.8489 14.848C14.9458 14.751 15.0002 14.6195 15.0001 14.4824C15 14.3453 14.9455 14.2138 14.8485 14.1169L11.7307 11L14.8485 7.88311Z"
                          fill="#EF7833"
                          stroke="#EF7833"
                          stroke-width="0.4"
                        />
                      </svg>
                      <span>{_t('verificationFailed')}</span>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
                          fill="#EF7833"
                        />
                      </svg>
                      <span>{_t('verificationPending')}</span>
                    </>
                  )}
                </div>
                {user?.onfido_kyc_success !== false && (
                  <button
                    className="reverify-btn"
                    onClick={(e) => {
                      navigate('/tenant/verifyId');
                    }}
                  >
                    <img src="/images/re-verify-icon.svg" alt="" />
                  </button>
                )}
              </div>
            </div>
            <div className="tenant-contact d-flex align-items-center gap-4">
              <span className="tenant-email">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                >
                  <path
                    d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L11.06 8.34C10.41 8.75 9.59 8.75 8.94 8.34L2.4 4.25C2.29973 4.19371 2.21192 4.11766 2.14189 4.02645C2.07186 3.93525 2.02106 3.83078 1.99258 3.71937C1.96409 3.60796 1.9585 3.49194 1.97616 3.37831C1.99381 3.26468 2.03434 3.15581 2.09528 3.0583C2.15623 2.96079 2.23632 2.87666 2.33073 2.811C2.42513 2.74533 2.53187 2.69951 2.6445 2.6763C2.75712 2.65309 2.87328 2.65297 2.98595 2.67595C3.09863 2.69893 3.20546 2.74453 3.3 2.81L10 7L16.7 2.81C16.7945 2.74453 16.9014 2.69893 17.014 2.67595C17.1267 2.65297 17.2429 2.65309 17.3555 2.6763C17.4681 2.69951 17.5749 2.74533 17.6693 2.811C17.7637 2.87666 17.8438 2.96079 17.9047 3.0583C17.9657 3.15581 18.0062 3.26468 18.0238 3.37831C18.0415 3.49194 18.0359 3.60796 18.0074 3.71937C17.9789 3.83078 17.9281 3.93525 17.8581 4.02645C17.7881 4.11766 17.7003 4.19371 17.6 4.25Z"
                    fill="#EF7833"
                  />
                </svg>
                <span>{user?.email}</span>
              </span>
              {haveValue(user?.phone_number) && (
                <span className="tenant-number">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M14.4231 17.9998C13.6387 17.9998 12.5367 17.7162 10.8867 16.7945C8.88015 15.6695 7.32813 14.6308 5.33246 12.6408C3.40831 10.7182 2.47196 9.47345 1.16147 7.08922C-0.319011 4.39722 -0.0666378 2.98614 0.215473 2.38305C0.551435 1.66224 1.04734 1.23112 1.68832 0.803208C2.05239 0.56472 2.43767 0.360281 2.83927 0.192487C2.87946 0.17521 2.91683 0.158736 2.95018 0.14387C3.14911 0.0542707 3.45051 -0.0811327 3.83228 0.0635119C4.08707 0.159138 4.31453 0.35481 4.67058 0.706377C5.40077 1.42639 6.39861 3.02993 6.76672 3.81744C7.01387 4.34821 7.17743 4.69857 7.17783 5.09152C7.17783 5.55157 6.94636 5.90635 6.66545 6.28926C6.61281 6.36118 6.56056 6.42988 6.50993 6.49658C6.20411 6.89837 6.137 7.01449 6.1812 7.22181C6.27082 7.63847 6.93912 8.8788 8.03743 9.97448C9.13573 11.0702 10.3405 11.6962 10.7589 11.7853C10.9751 11.8316 11.0936 11.7616 11.5084 11.445C11.5678 11.3996 11.6289 11.3526 11.6928 11.3056C12.1212 10.987 12.4596 10.7616 12.9089 10.7616H12.9113C13.3023 10.7616 13.6371 10.9311 14.1916 11.2108C14.915 11.5756 16.5671 12.5604 17.2916 13.2913C17.6441 13.6464 17.8406 13.8731 17.9366 14.1274C18.0813 14.5103 17.9451 14.8104 17.8563 15.0113C17.8414 15.0447 17.8249 15.0812 17.8076 15.1218C17.6385 15.5226 17.4328 15.907 17.1932 16.2701C16.766 16.909 16.3332 17.4036 15.6106 17.7399C15.2396 17.9154 14.8335 18.0043 14.4231 17.9998Z"
                      fill="#EF7833"
                    />
                  </svg>
                  <span>+{user?.phone_number}</span>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="reward-points-wrapper">
          <h3 className="reward-head">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="22"
              viewBox="0 0 24 22"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.37022 3.98145C2.47638 3.98145 1.61916 4.33652 0.987116 4.96856C0.355076 5.6006 0 6.45783 0 7.35167V18.3815C0 19.2753 0.355076 20.1326 0.987116 20.7646C1.61916 21.3966 2.47638 21.7517 3.37022 21.7517H20.5277C21.4216 21.7517 22.2788 21.3966 22.9108 20.7646C23.5429 20.1326 23.8979 19.2753 23.8979 18.3815V7.35167C23.8979 6.45783 23.5429 5.6006 22.9108 4.96856C22.2788 4.33652 21.4216 3.98145 20.5277 3.98145H3.37022ZM17.4639 11.3347C17.0576 11.3347 16.6679 11.4961 16.3807 11.7833C16.0934 12.0706 15.932 12.4603 15.932 12.8666C15.932 13.2729 16.0934 13.6625 16.3807 13.9498C16.6679 14.2371 17.0576 14.3985 17.4639 14.3985C17.8702 14.3985 18.2598 14.2371 18.5471 13.9498C18.8344 13.6625 18.9958 13.2729 18.9958 12.8666C18.9958 12.4603 18.8344 12.0706 18.5471 11.7833C18.2598 11.4961 17.8702 11.3347 17.4639 11.3347Z"
                fill="#A392EF"
              />
              <path
                d="M17.4455 0.0826545C17.8083 -0.0140421 18.1886 -0.0260717 18.5568 0.0474941C18.925 0.12106 19.2715 0.278254 19.5693 0.506944C19.8672 0.735634 20.1085 1.0297 20.2747 1.36646C20.4408 1.70321 20.5274 2.07364 20.5277 2.44916H8.27234L17.4455 0.0826545Z"
                fill="#A392EF"
              />
            </svg>
            <span>{_t('rewardPoints')}</span>
          </h3>
          <p className="reward-points">{user?.reward_points || 0}</p>
        </div>
      </div>

      <div className="tenant-info-mobile d-flex flex-column d-md-none mb-4">
        <div className="tenant-info-wrapper mobile d-flex flex-column d-md-none justify-content-between">
          <div className="d-flex align-items-center justify-content-between gap-2 ">
            <div className="d-flex align-items-center gap-2">
              <div className="tenant-badge-name mobile">
                <>
                  {user?.fname && user?.fname.charAt(0).toUpperCase()}
                  {user?.lname && user?.lname.charAt(0).toUpperCase()}
                </>
              </div>
              <h4 className="tenant-name m-0">
                {user?.fname} {user?.lname}
              </h4>
              <div
                className={`tenant-status mobile ${
                  user?.onfido_kyc_success
                    ? 'complete'
                    : user?.onfido_user_kyc?.onfido_workflow_res?.status ==
                      'declined'
                    ? 'failed'
                    : 'pending'
                }`}
              >
                {user?.onfido_kyc_success ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M15.2308 8.32699L9.42863 14.3845L6.76929 11.6081L7.45105 10.8964L9.42863 12.9559L14.5491 7.61523L15.2308 8.32699Z"
                      fill="#253837"
                      stroke="#253837"
                      stroke-width="0.6"
                    />
                  </svg>
                ) : user?.onfido_user_kyc?.onfido_workflow_res?.status ==
                  'declined' ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <circle cx="11" cy="11" r="11" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.8485 7.88311C14.8965 7.83513 14.9346 7.77816 14.9607 7.71545C14.9867 7.65274 15.0001 7.58552 15.0001 7.51763C15.0002 7.44974 14.9868 7.3825 14.9609 7.31976C14.935 7.25702 14.8969 7.2 14.8489 7.15197C14.801 7.10393 14.744 7.06581 14.6813 7.03979C14.6186 7.01377 14.5513 7.00036 14.4835 7.00032C14.4156 7.00028 14.3483 7.01361 14.2856 7.03955C14.2228 7.06549 14.1658 7.10354 14.1178 7.15152L11 10.2693L7.88311 7.15152C7.78609 7.0545 7.65451 7 7.51731 7C7.38011 7 7.24853 7.0545 7.15152 7.15152C7.0545 7.24853 7 7.38011 7 7.51731C7 7.65451 7.0545 7.78609 7.15152 7.88311L10.2693 11L7.15152 14.1169C7.10348 14.1649 7.06538 14.222 7.03938 14.2847C7.01338 14.3475 7 14.4148 7 14.4827C7 14.5506 7.01338 14.6179 7.03938 14.6807C7.06538 14.7434 7.10348 14.8004 7.15152 14.8485C7.24853 14.9455 7.38011 15 7.51731 15C7.58525 15 7.65252 14.9866 7.71528 14.9606C7.77804 14.9346 7.83507 14.8965 7.88311 14.8485L11 11.7307L14.1178 14.8485C14.2148 14.9454 14.3463 14.9998 14.4835 14.9997C14.6206 14.9996 14.752 14.945 14.8489 14.848C14.9458 14.751 15.0002 14.6195 15.0001 14.4824C15 14.3453 14.9455 14.2138 14.8485 14.1169L11.7307 11L14.8485 7.88311Z"
                      fill="#EF7833"
                      stroke="#EF7833"
                      stroke-width="0.4"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
                      fill="#EF7833"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div>
              {user?.onfido_kyc_success !== false && (
                <div className="d-flex gap-2">
                  <button
                    className="reverify-btn"
                    onClick={(e) => {
                      navigate('/tenant/verifyId');
                    }}
                  >
                    <img src="/images/re-verify-icon.svg" alt="" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-2 flex-wrap">
            <span className="d-flex align-items-center gap-2 fs-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="18"
                viewBox="0 0 20 16"
                fill="none"
              >
                <path
                  d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L11.06 8.34C10.41 8.75 9.59 8.75 8.94 8.34L2.4 4.25C2.29973 4.19371 2.21192 4.11766 2.14189 4.02645C2.07186 3.93525 2.02106 3.83078 1.99258 3.71937C1.96409 3.60796 1.9585 3.49194 1.97616 3.37831C1.99381 3.26468 2.03434 3.15581 2.09528 3.0583C2.15623 2.96079 2.23632 2.87666 2.33073 2.811C2.42513 2.74533 2.53187 2.69951 2.6445 2.6763C2.75712 2.65309 2.87328 2.65297 2.98595 2.67595C3.09863 2.69893 3.20546 2.74453 3.3 2.81L10 7L16.7 2.81C16.7945 2.74453 16.9014 2.69893 17.014 2.67595C17.1267 2.65297 17.2429 2.65309 17.3555 2.6763C17.4681 2.69951 17.5749 2.74533 17.6693 2.811C17.7637 2.87666 17.8438 2.96079 17.9047 3.0583C17.9657 3.15581 18.0062 3.26468 18.0238 3.37831C18.0415 3.49194 18.0359 3.60796 18.0074 3.71937C17.9789 3.83078 17.9281 3.93525 17.8581 4.02645C17.7881 4.11766 17.7003 4.19371 17.6 4.25Z"
                  fill="#EF7833"
                />
              </svg>
              <span>{user?.email}</span>
            </span>
            {haveValue(user?.phone_number) && (
              <span className="d-flex align-items-center gap-2 fs-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M14.4231 17.9998C13.6387 17.9998 12.5367 17.7162 10.8867 16.7945C8.88015 15.6695 7.32813 14.6308 5.33246 12.6408C3.40831 10.7182 2.47196 9.47345 1.16147 7.08922C-0.319011 4.39722 -0.0666378 2.98614 0.215473 2.38305C0.551435 1.66224 1.04734 1.23112 1.68832 0.803208C2.05239 0.56472 2.43767 0.360281 2.83927 0.192487C2.87946 0.17521 2.91683 0.158736 2.95018 0.14387C3.14911 0.0542707 3.45051 -0.0811327 3.83228 0.0635119C4.08707 0.159138 4.31453 0.35481 4.67058 0.706377C5.40077 1.42639 6.39861 3.02993 6.76672 3.81744C7.01387 4.34821 7.17743 4.69857 7.17783 5.09152C7.17783 5.55157 6.94636 5.90635 6.66545 6.28926C6.61281 6.36118 6.56056 6.42988 6.50993 6.49658C6.20411 6.89837 6.137 7.01449 6.1812 7.22181C6.27082 7.63847 6.93912 8.8788 8.03743 9.97448C9.13573 11.0702 10.3405 11.6962 10.7589 11.7853C10.9751 11.8316 11.0936 11.7616 11.5084 11.445C11.5678 11.3996 11.6289 11.3526 11.6928 11.3056C12.1212 10.987 12.4596 10.7616 12.9089 10.7616H12.9113C13.3023 10.7616 13.6371 10.9311 14.1916 11.2108C14.915 11.5756 16.5671 12.5604 17.2916 13.2913C17.6441 13.6464 17.8406 13.8731 17.9366 14.1274C18.0813 14.5103 17.9451 14.8104 17.8563 15.0113C17.8414 15.0447 17.8249 15.0812 17.8076 15.1218C17.6385 15.5226 17.4328 15.907 17.1932 16.2701C16.766 16.909 16.3332 17.4036 15.6106 17.7399C15.2396 17.9154 14.8335 18.0043 14.4231 17.9998Z"
                    fill="#EF7833"
                  />
                </svg>
                <span>{user?.phone_number}</span>
              </span>
            )}
          </div>
        </div>
        <div className="reward-points-wrapper mobile d-flex align-items-center justify-content-between pt-2 pb-3 px-3">
          <h5 className="reward-head mobile d-flex align-items-center gap-3 m-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="22"
              viewBox="0 0 24 22"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.37022 3.98145C2.47638 3.98145 1.61916 4.33652 0.987116 4.96856C0.355076 5.6006 0 6.45783 0 7.35167V18.3815C0 19.2753 0.355076 20.1326 0.987116 20.7646C1.61916 21.3966 2.47638 21.7517 3.37022 21.7517H20.5277C21.4216 21.7517 22.2788 21.3966 22.9108 20.7646C23.5429 20.1326 23.8979 19.2753 23.8979 18.3815V7.35167C23.8979 6.45783 23.5429 5.6006 22.9108 4.96856C22.2788 4.33652 21.4216 3.98145 20.5277 3.98145H3.37022ZM17.4639 11.3347C17.0576 11.3347 16.6679 11.4961 16.3807 11.7833C16.0934 12.0706 15.932 12.4603 15.932 12.8666C15.932 13.2729 16.0934 13.6625 16.3807 13.9498C16.6679 14.2371 17.0576 14.3985 17.4639 14.3985C17.8702 14.3985 18.2598 14.2371 18.5471 13.9498C18.8344 13.6625 18.9958 13.2729 18.9958 12.8666C18.9958 12.4603 18.8344 12.0706 18.5471 11.7833C18.2598 11.4961 17.8702 11.3347 17.4639 11.3347Z"
                fill="white"
              />
              <path
                d="M17.4455 0.0826545C17.8083 -0.0140421 18.1886 -0.0260717 18.5568 0.0474941C18.925 0.12106 19.2715 0.278254 19.5693 0.506944C19.8672 0.735634 20.1085 1.0297 20.2747 1.36646C20.4408 1.70321 20.5274 2.07364 20.5277 2.44916H8.27234L17.4455 0.0826545Z"
                fill="white"
              />
            </svg>
            <span>{_t('rewardPoints')}</span>
          </h5>
          <h3 className="reward-points mobile m-0">
            {user?.reward_points || 0}
          </h3>
        </div>
      </div>
    </>
  );
};

export default TenantDetails;
