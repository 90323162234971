import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBqcrV0WJKOJ0v2__Rdrc-_mv246pr9Wns",
    authDomain: "skor-2c8c6.firebaseapp.com",
    projectId: "skor-2c8c6",
    storageBucket: "skor-2c8c6.appspot.com",
    messagingSenderId: "757131387387",
    appId: "1:757131387387:web:f546f852487cbb2b49ab5e",
    measurementId: "G-ZYV9CHMG0H"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export {
    auth
}
