import { Watch } from 'react-loader-spinner';
import useTranslator from '../hooks/useTranslator';
import { haveValue } from '../helper/common';

const Loader = ({ waiting_text = null }) => {
  const { _t } = useTranslator();

  return (
    <>
      {/* <div className="margin-div"></div> */}
      <div className="spinner-loader">
        <Watch
          height="80"
          width="80"
          radius="48"
          color="#253837"
          ariaLabel="watch-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
        <p style={{ color: '#253837' }}>
          {haveValue(waiting_text) ? _t(waiting_text) : _t('pleaseWait')}
        </p>
      </div>
    </>
  );
};

export default Loader;
