import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function useTranslator() {
  const { t } = useTranslation();

  const _t = (key, namespace = "translation") => {
    return t(key, { ns: namespace });
  };

  return {
    _t,
  };
}
