import { haveValue } from "../../helper/common";
import useTranslator from "../../hooks/useTranslator";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap/esm";

const AcceptAssignedProp = (props) => {
  const { _t } = useTranslator();
  const { handleNextStep, formData } = props;
  const landlordName = `${
    haveValue(formData?.landlord_id?.fname_companyName)
      ? formData?.landlord_id?.fname_companyName
      : ""
  } ${
    haveValue(formData?.landlord_id?.lname) ? formData?.landlord_id?.lname : ""
  }`;
  const propAddr = `${formData?.property_id?.street} - ${formData?.property_id?.street_number}, ${formData?.property_id?.floor_number}-${formData?.property_id?.flat_number}`;

  return (
    <>
      <div className="get-setup">
        <div className="paymentdetails-body">
          <h1>
            {landlordName} {_t("hasSentYouRentalDetails")}
          </h1>
          <h5>
            {"Time to Set up Your Payment for "}
            {propAddr}
          </h5>

          <Row className="five-steps custom-pb mobile-steps-box">
            <Col lg={6}>
              <div className="step-one">
                <p className="step-num">1</p>
                <img src="/images/stepone-img.svg"></img>
                <p className="add-detail">{_t("reviewRentalDetails")}</p>
              </div>
            </Col>

            <Col className="middlestep" lg={6}>
              <div className="step-one">
                <p className="step-num">2</p>
                <img src="/images/dollarone.svg"></img>
                <p className="add-detail">{_t("addPaymentDetails")}</p>
              </div>
            </Col>
          </Row>
          <button className="btn btngreen btn-up" onClick={handleNextStep}>
            {" "}
            {_t("startNow")}{" "}
          </button>
        </div>
        <div class="getsetup-footer">
          <img src="/images/getset-bg.svg"></img>
        </div>
      </div>
    </>
  );
};

export default AcceptAssignedProp;
