import { useContext, useEffect, useState } from "react";
import useTranslator from "../../hooks/useTranslator";
import AuthContext from "../../context/auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { haveValue, skipMaccUser } from "../../helper/common";
import { Modal } from "react-bootstrap";
import moment from "moment/moment";
import constants from "../../Utils/constants";

const TenantProfileChecklist = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { _t } = useTranslator();
  const navigate = useNavigate();

  const [profileCompletion, setProfileCompletion] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    var temp = 0;
    if (user?.profile_completed) {
      temp += 25;
    }
    if (user?.docs_uploaded) {
      temp += 25;
    }
    if (user?.onfido_kyc_success) {
      temp += 25;
    }
    if (user?.gurantor_id?.paymentScores || user?.paymentScores) {
      temp += 25;
    }

    setProfileCompletion(temp);

    //
    if (
      user &&
      user?.onfido_kyc_success == false &&
      user?.onfido_user_kyc &&
      user?.onfido_user_kyc?.onfido_doc &&
      user?.onfido_user_kyc?.onfido_workflow_res?.status != "declined"
    ) {
      const modal = localStorage.getItem("modal");
      if (!haveValue(modal) && showModal === false) {
        const currentUtcTime = moment().utc().format();
        const kyc_started_at = user?.onfido_user_kyc.kyc_started_at;
        if (currentUtcTime && kyc_started_at) {
          const differenceInMinutes = moment
            .utc(currentUtcTime)
            .diff(moment.utc(kyc_started_at), "minutes");
          if (differenceInMinutes > 2) {
            setShowModal(true);
          }
        }
      }
    }
  }, [user]);

  const navigateStepform = () => {
    navigate("/tenant/stepform");
  };

  const navigateVerifyId = () => {
    navigate("/tenant/verifyId");
  };

  const navigateBankConnect = () => {
    navigate("/tenant/connectBank");
  };

  const navigateDocument = () => {
    navigate("/tenant/document");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    localStorage.setItem("modal", true);
  };

  const renderKycButton = (user) => {
    if (user?.onfido_kyc_success === true) {
      return <button className="completed-btn">{_t("completed")}</button>;
    }

    if (
      user?.onfido_user_kyc?.onfido_doc &&
      user?.onfido_user_kyc?.onfido_workflow_res?.status !== "declined"
    ) {
      const currentUtcTime = moment().utc().format();
      const kyc_started_at = user?.onfido_user_kyc.kyc_started_at;
      if (currentUtcTime && kyc_started_at) {
        const differenceInMinutes = moment
          .utc(currentUtcTime)
          .diff(moment.utc(kyc_started_at), "minutes");
        if (differenceInMinutes >= 10) {
          return (
            <button onClick={navigateVerifyId} className="incomplete-btn">
              {_t("retry")}
            </button>
          );
        }
      }
      return <button className="view-progress">{_t("inProgress")}</button>;
    }

    return (
      <button
        onClick={navigateVerifyId}
        disabled={!user?.profile_completed}
        className="incomplete-btn"
      >
        {_t("complete")}
      </button>
    );
  };

  return (
    <>
      <div id="onfido-mount"></div>
      {profileCompletion == 100 ? (
        <></>
      ) : (
        <div className="pending-steps-wrapper">
          <div className="d-flex align-items-center justify-content-between tile-design profile-status-wrapper">
            <div>
              <h3 className="tiles-main-heading">
                {" "}
                {_t("completeAndGetSkored")}{" "}
              </h3>
              <p className="tile-desc">
                {_t(
                  "yourDataIsStillNotEnoughForAProfilePleaseCompleteTheFollowingStepsToCompleteYourProfileAndGetScored"
                )}
              </p>
            </div>
            <div className="position-relative steps-progress-bar">
              <div class="circle">
                <div
                  class="mask full"
                  style={{ transform: `rotate(${profileCompletion * 1.8}deg)` }}
                >
                  <div
                    class="fill"
                    style={{
                      transform: `rotate(${profileCompletion * 1.8}deg)`,
                    }}
                  ></div>
                </div>
                <div class="mask half">
                  <div
                    class="fill"
                    style={{
                      transform: `rotate(${profileCompletion * 1.8}deg)`,
                    }}
                  ></div>
                </div>
                <div class="inside-circle"> {profileCompletion}% </div>
              </div>
            </div>
          </div>

          <ul>
            <li className="d-flex tile-design">
              <div className="tile-content-wrapper">
                <img src="/images/profile-icon.svg" alt="" />
                <div className="tile-content">
                  <h4 className="tile-heading">{_t("completeProfile")}</h4>
                  {user?.profile_completed ? (
                    <p className="tile-desc-completed">
                      {_t(
                        "wellDoneNowLandlordsWillStartSeeingYourProfileVerifyYourIdentityToFurtherIncreaseYourChances"
                      )}
                    </p>
                  ) : (
                    <p className="tile-desc">
                      {_t(
                        "welcomeToYourProfileCompleteYourBasicInformationToStartSharingYourProfileWithLandlords"
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
                <div className="tile-points">
                  <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                  <span>20 {_t("points")}</span>
                </div>

                {user?.profile_completed ? (
                  <button className="completed-btn">{_t("completed")}</button>
                ) : (
                  <button className="incomplete-btn" onClick={navigateStepform}>
                    {_t("complete")}
                  </button>
                )}
              </div>
            </li>
            <li className="d-flex tile-design">
              <div className="tile-content-wrapper">
                <img src="/images/verify-home-icon.svg" alt="" />
                <div className="tile-content">
                  <h4 className="tile-heading">{_t("verifyID")}</h4>
                  {user?.onfido_kyc_success == true ? (
                    <p className="tile-desc-completed">
                      {_t(
                        "awesomeNowThatYouHaveVerifiedYourIdYouHaveSignificantlyIncreasedYourchancesOfBeingConsideredAsACandidate"
                      )}
                    </p>
                  ) : (
                    <p className="tile-desc">
                      {_t(
                        "verifyingYourIdentityProvesYouAreAGenuineCandidateInfact90%OfLandlordsOnlyRentTheirPropertiesToIdVerifiedCandidates"
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
                <div className="tile-points">
                  <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                  <span>30 {_t("points")}</span>
                </div>
                {renderKycButton(user)}
              </div>
            </li>
            <li className="d-flex tile-design">
              <div className="tile-content-wrapper">
                <img src="/images/financial-home-icon.svg" alt="" />
                <div className="tile-content">
                  <h4 className="tile-heading">{_t("financialCheck")}</h4>
                  {((!haveValue(user?.have_gurantor) ||
                    !user?.have_gurantor ||
                    user?.have_gurantor == "false") &&
                    (!haveValue(user?.kyc_success) ||
                      !user?.kyc_success ||
                      user?.kyc_success == "false")) ||
                  (!haveValue(user?.gurantor_id?.paymentScores) &&
                    !haveValue(user?.paymentScores)) ? (
                    <p className="tile-desc">
                      {_t(
                        "verifyingYourFinancialInformationBoostsYourCredibilityMostOfTheLandlordsSelectProfilesWithBankVerification"
                      )}
                    </p>
                  ) : (
                    <p className="tile-desc-completed">
                      {_t(
                        "almostThereYouAreNowOneOfTheTopProfilesLandlordsWillReview"
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
                <div className="tile-points">
                  <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                  <span>50 {_t("points")}</span>
                </div>
                {(!haveValue(user?.have_gurantor) ||
                  !user?.have_gurantor ||
                  user?.have_gurantor == "false") &&
                (!haveValue(user?.kyc_success) ||
                  !user?.kyc_success ||
                  user?.kyc_success == "false") ? (
                  <button
                    onClick={navigateBankConnect}
                    className="incomplete-btn"
                    disabled={!user?.profile_completed}
                  >
                    {_t("complete")}
                  </button>
                ) : !haveValue(user?.gurantor_id?.paymentScores) &&
                  !haveValue(user?.paymentScores) ? (
                  <button
                    className="view-progress"
                    onClick={navigateBankConnect}
                  >
                    {_t("viewProgress")}
                  </button>
                ) : (
                  <button className="completed-btn">{_t("completed")}</button>
                )}
              </div>
            </li>
            <li
              className={`d-flex tile-design mb-0 border-0 ${
                skipMaccUser(user?.user_type) && "li_disabled"
              }`}
            >
              <div className="tile-content-wrapper">
                <img src="/images/document-home-icon.svg" alt="" />
                <div className="tile-content">
                  <h4 className="tile-heading">{_t("provideDocuments")}</h4>
                  {user?.docs_uploaded ? (
                    <p className="tile-desc-completed">
                      {_t(
                        "greatJobYourDocumentsAreNowCheckedNowYouCanStartSharingYourProfileWithLandlords"
                      )}
                    </p>
                  ) : (
                    <p className="tile-desc">
                      {_t(
                        "documentationIsCrucialToCompleteYourProfileSubmitItAndStartSharingYourProfile"
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
                <div className="tile-points">
                  <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                  <span>50 {_t("points")}</span>
                </div>
                {user?.docs_uploaded ? (
                  <button className="completed-btn">{_t("completed")}</button>
                ) : (
                  <button
                    onClick={navigateDocument}
                    disabled={!user?.profile_completed}
                    className="incomplete-btn"
                  >
                    {_t("complete")}
                  </button>
                )}
              </div>
            </li>
          </ul>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="newmodalui asguarantor"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {_t(
              "verificationInProgressTheVerificationProcessMayTakeSometimePleaseBePatientAsItCanTakeUpTo10MinutesToComplete"
            )}
          </p>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btngreen"
              onClick={handleCloseModal}
            >
              close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TenantProfileChecklist;
