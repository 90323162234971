import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../hooks/useTranslator";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { haveValue } from "../helper/common";
import AuthContext from "../context/auth/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TenantContext from "../context/tenant/TenantContext";
import { Modal } from "react-bootstrap";

const StepForm = () => {
  const authContext = useContext(AuthContext);
  const { user, setLoading, loadUser, setLoggedInUser, toggleLogout } =
    authContext;
  const tenantContext = useContext(TenantContext);
  const { getUniversityListing } = tenantContext;

  const navigate = useNavigate();

  const { _t } = useTranslator();
  const [step, setStep] = useState(1);
  const [disabledSubmit, setDisableSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [idType, setIdType] = useState("dni_nie");
  const [goHomeConfirm, setGoHomeConfirm] = useState(false);
  const [countryCode, setCountryCode] = useState("es");

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (localStorage.getItem("usertype") == "tenant") {
      setFormData((prevData) => ({
        ...prevData,
        ["phone_number"]: phoneNumber,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, ["phone"]: phoneNumber }));
    }
  }, [phoneNumber]);

  const applyProfileData = () => {
    setFormData({
      fname: user?.fname,
      lname: user?.lname,
      fname_companyName: user?.fname_companyName,
      password: "",
      nationality: "",
      dni_nie: "",
      passport_id: "",
      duration_type: "",
      years_of_living_in_country: "",
      rented_locally_before: "",
      email_previous_landlord: "",
      type_of_student: "",
      name_of_university: "",
      employment_status: "",
      position: "",
      name_of_company: "",
      current_annual_income: "",
      email_of_university: "",
      role: "",
      is_freelancer: "false",
      landlordtype: "",
      isLandlordInComp: "",
      company_name: "",
      email: user?.email,
      cif_dni_nie: "",
      phone: "",
      phone_number: "",
      current_course: "",
      name_of_current_university: "",
      university_end_date: "",
      have_sharer: "",
      sharers: [{ name: "", email: "" }],
      lang: localStorage.getItem("i18nextLng"),
    });
  };

  useEffect(() => {
    applyProfileData();
  }, [user]);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    loadUser(usertype);
    getLocation();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setStep((prevStep) => prevStep + 1);
    setCurrentStep((prev) => prev + 1);
  };

  const handleGoBack = () => {
    setStep((prevStep) => prevStep - 1);
    setCurrentStep((prev) => prev - 1);
  };

  const handleGoBackNoProg = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    const usertype = localStorage.getItem("usertype");
    formData.role = usertype;

    let userid = localStorage.getItem("saveduserid");
    createUserProfile(userid, formData);
  };

  const createUserProfile = async (id, profileData) => {
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`,
        profileData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then(async (res) => {
        setLoading(false);

        const token = localStorage.getItem("token");
        const is_verified = true;
        const usertype = localStorage.getItem("usertype");
        if (window.location.host === process.env.REACT_APP_MACC_URL_NAME) {
          if (token && is_verified) {
            const app_type = "macc";
            // Create a query string from the constants
            const queryParams = new URLSearchParams({
              token: token || "", // Append token
              usertype: usertype || "", // Append usertype
              is_verified: is_verified || "", // Append is_verified
              saveduserid: id || "", // Append saveduserid
              external_customer_id: id || "", // Append external_customer_id
              app_type: app_type || "", //Append app_type
            });
            //clear local storage
            localStorage.removeItem("usertype");
            localStorage.removeItem("is_verified");
            localStorage.removeItem("saveduserid");
            localStorage.removeItem("external_customer_id");
            localStorage.removeItem("macc");
            // Redirect to the new URL with query parameters
            window.location.replace(
              `${window.location.protocol}//${
                process.env.REACT_APP_URL_NAME
              }/redirect?${queryParams.toString()}`
            );
            return false;
          }
        }

        await setLoggedInUser();
        await loadUser(usertype);

        if (usertype === "tenant") {
          navigate("/tenant");
        } else {
          navigate("/home");
        }
        setDisableSubmit(false);
      })
      .catch((err) => {
        setLoading(false);
        let serverResponse = err;
        toast.error(serverResponse.response.data.error);
        setDisableSubmit(false);
      });
  };

  const handleNextAccType = (e) => {
    e.preventDefault();
    if (formData?.landlordtype == "agent") setStep((prevStep) => prevStep + 2);
    else setStep((prevStep) => prevStep + 1);
  };
  const handleNextLandlordType = (e) => {
    e.preventDefault();
    if (formData?.isLandlordInComp == "company")
      setStep((prevStep) => prevStep + 1);
    else {
      setStep((prevStep) => prevStep + 2);
      setCurrentStep((prev) => prev + 1);
    }
  };
  const handleBackCompanyDetails = (e) => {
    e.preventDefault();
    if (formData?.landlordtype == "agent") {
      setStep((prevStep) => prevStep - 2);
    } else setStep((prevStep) => prevStep - 1);
  };
  const handleGoBackPhone = (e) => {
    e.preventDefault();
    if (formData?.isLandlordInComp == "company")
      setStep((prevStep) => prevStep - 1);
    else setStep((prevStep) => prevStep - 2);
    setCurrentStep((prev) => prev - 1);
  };

  const handleGoHomeConfirm = () => {
    toggleLogout();
    navigate("/userrole");
  };

  // Function to scroll to the bottom of the div
  function scrollToBottom() {
    var scrollableDiv = document.getElementById("flatmate_div");
    if (haveValue(document.getElementById("flatmate_div")))
      scrollableDiv.scrollTop =
        scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
  }

  useEffect(() => {
    scrollToBottom();
  }, [formData?.sharers]);

  const getLocation = async () => {
    axios({ url: `https://ipapi.co/json/` })
      .then((res) => {
        setCountryCode(res?.data?.country_code.toString().toLowerCase());
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="Auth-wrapper-2">
        <div className="logobarwrapper">
          <div className="logobar">
            <Container className="p-0 text-center">
              <div className="go-back-home">
                <a href={undefined} onClick={(e) => setGoHomeConfirm(true)}>
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="27"
                      cy="27"
                      r="26.5"
                      fill="#253837"
                      stroke="#A3EB64"
                    />
                    <path
                      d="M24.9821 22.1376L24.9884 22.1314L24.995 22.1256C25.0335 22.0911 25.0632 22.0505 25.0834 22.007C25.1035 21.9635 25.114 21.9173 25.1149 21.8711C25.1157 21.8249 25.1069 21.7785 25.0885 21.7346C25.07 21.6906 25.0419 21.6493 25.0049 21.6137C24.9679 21.578 24.9226 21.5489 24.8714 21.529C24.8202 21.5091 24.7647 21.4991 24.7085 21.5001C24.6522 21.501 24.5972 21.5129 24.5469 21.5344C24.4967 21.556 24.4527 21.5865 24.4172 21.6232L24.4111 21.6295L24.4047 21.6356L19.6098 26.2488C19.6098 26.2489 19.6097 26.2489 19.6097 26.2489C19.5371 26.319 19.5 26.4097 19.5 26.5C19.5 26.5903 19.5371 26.681 19.6097 26.7511C19.6097 26.7511 19.6098 26.7511 19.6098 26.7512L24.4047 31.3644L24.4111 31.3705L24.4172 31.3768C24.4527 31.4135 24.4967 31.444 24.5469 31.4656C24.5972 31.4871 24.6522 31.499 24.7085 31.4999C24.7647 31.5009 24.8202 31.4909 24.8714 31.471C24.9226 31.4511 24.9679 31.422 25.0049 31.3863C25.0419 31.3507 25.07 31.3094 25.0885 31.2654C25.1069 31.2215 25.1157 31.1751 25.1149 31.1289C25.114 31.0827 25.1035 31.0365 25.0834 30.993C25.0632 30.9495 25.0335 30.909 24.995 30.8744L24.9884 30.8686L24.9821 30.8624L21.7214 27.7253L20.8272 26.865L22.0681 26.865L33.6842 26.865C33.7962 26.865 33.8998 26.822 33.9733 26.7513C34.0462 26.6813 34.0833 26.5904 34.0833 26.5C34.0833 26.4096 34.0462 26.3187 33.9733 26.2487C33.8998 26.178 33.7962 26.135 33.6842 26.135L22.0681 26.135L20.8272 26.135L21.7214 25.2747L24.9821 22.1376Z"
                      fill="#FFB65A"
                      stroke="white"
                    />
                  </svg>
                  <span>{_t("goBackToHome")} </span>
                </a>
              </div>
              <img
                className="logo-setting"
                src="../images/logo.svg"
                alt="logo"
              />
            </Container>
          </div>
          {localStorage.getItem("usertype") == "tenant" ? <></> : <></>}
        </div>

        {localStorage.getItem("usertype") == "tenant" ? (
          <>
            <div className="stepformauthbar-inner">
              <div className="authbar-inner">
                <div className="fullbar">
                  <div className="right-contentbar">
                    {step === 1 && (
                      <div className="resend-text">
                        <h4>{_t("completeYourProfile")}</h4>
                        <p>{_t("pleaseEnterDetails")}</p>
                        <Form className="loginform" onSubmit={handleSubmit}>
                          <div className="auth-form">
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("firstName")}
                                <span className="mandatory_span">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                name="fname"
                                value={formData.fname}
                                onChange={handleInputChange}
                                required
                                placeholder={_t("enterFirstName")}
                              />
                            </div>
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("lastName")}
                                <span className="mandatory_span">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                name="lname"
                                value={formData.lname}
                                onChange={handleInputChange}
                                required
                                placeholder={_t("enterLastName")}
                              />
                            </div>
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("phoneNumber")}
                                <span className="mandatory_span">*</span>
                              </label>
                              <PhoneInput
                                className="phone_input"
                                international
                                country={countryCode}
                                value={formData.phone_number}
                                onChange={setPhoneNumber}
                              />
                            </div>
                            <div className="btngroup row100">
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  !haveValue(formData.fname) ||
                                  !haveValue(formData.lname) ||
                                  !haveValue(formData.phone_number) ||
                                  disabledSubmit
                                }
                              >
                                {_t("submit")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="stepformauthbar-inner">
              <div className="authbar-inner">
                <div className="fullbar">
                  <div className="right-contentbar">
                    {step === 1 && (
                      <div className="resend-text">
                        <h4>{_t("completeYourProfile")}?</h4>
                        <p>{_t("pleaseEnterDetails")}</p>
                        <Form className="loginform" onSubmit={handleSubmit}>
                          <div className="auth-form">
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("firstName")}
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                name="fname_companyName"
                                value={formData.fname_companyName}
                                onChange={handleInputChange}
                                required
                                placeholder={_t("enterFirstName")}
                              />
                            </div>
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("lastName")}
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                name="lname"
                                value={formData.lname}
                                onChange={handleInputChange}
                                required
                                placeholder={_t("enterLastName")}
                              />
                            </div>
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("phoneNumber")}
                                <span className="mandatory_span">*</span>
                              </label>
                              <PhoneInput
                                className="phone_input"
                                international
                                country={countryCode}
                                // defaultCountry="RU"
                                value={formData.phone}
                                onChange={setPhoneNumber}
                              />
                            </div>
                            <div className="btngroup row100">
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={disabledSubmit}
                              >
                                {_t("submit")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 2 && (
                      <div className="resend-text">
                        <h4>{_t("createAccountAs")}?</h4>
                        <p>{_t("pleaseSelectYourAnswer")}</p>
                        <Form
                          className="loginform"
                          onSubmit={handleNextAccType}
                        >
                          <div className="auth-form">
                            <div className="forminput row100 inlinecheckboxes">
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="landlordtype"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.landlordtype == "agent"
                                        ? true
                                        : false
                                    }
                                    value="agent"
                                  />
                                  <div class="boxes">{_t("agent")}</div>
                                </label>
                              </div>
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="landlordtype"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.landlordtype == "landlord"
                                        ? true
                                        : false
                                    }
                                    value="landlord"
                                  />
                                  <div class="boxes">{_t("landlord")}</div>
                                </label>
                              </div>
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBack}
                              >
                                {_t("goBack")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.landlordtype)
                                    ? false
                                    : true
                                }
                              >
                                {_t("next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 3 && (
                      <div className="resend-text">
                        <h4>{_t("landlordType")}?</h4>
                        <p>{_t("pleaseSelectYourAnswer")}</p>
                        <Form
                          className="loginform"
                          onSubmit={handleNextLandlordType}
                        >
                          <div className="auth-form">
                            <div className="forminput row100 inlinecheckboxes">
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="isLandlordInComp"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.isLandlordInComp == "company"
                                        ? true
                                        : false
                                    }
                                    value="company"
                                  />
                                  <div class="boxes">{_t("company")}</div>
                                </label>
                              </div>
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="isLandlordInComp"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.isLandlordInComp == "individual"
                                        ? true
                                        : false
                                    }
                                    value="individual"
                                  />
                                  <div class="boxes">{_t("individual")}</div>
                                </label>
                              </div>
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBackNoProg}
                              >
                                {_t("goBack")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.isLandlordInComp)
                                    ? false
                                    : true
                                }
                              >
                                {_t("next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 4 && (
                      <div className="resend-text">
                        <h4>{_t("yourCompanyDetails")}</h4>
                        <p>{_t("pleaseEnterDetails")}</p>
                        <Form className="loginform" onSubmit={handleNext}>
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("companyName")}
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                required
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleInputChange}
                                placeholder={_t("enterCompanyName")}
                              />
                            </div>
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("companyEmail")}
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control user-auth"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder={_t("enterCompanyEmail")}
                              />
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleBackCompanyDetails}
                              >
                                {_t("goBack")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.company_name) &&
                                  haveValue(formData.email)
                                    ? false
                                    : true
                                }
                              >
                                {_t("next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 5 && (
                      <div className="resend-text">
                        <h4>{_t("enterYourPhoneNumber")}</h4>
                        <p>{_t("pleaseEnterDetails")}</p>
                        <Form className="loginform" onSubmit={handleNext}>
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("phoneNumber")}
                              </label>
                              <PhoneInput
                                className="phone_input"
                                international
                                country={countryCode}
                                // defaultCountry="RU"
                                value={formData.phone}
                                onChange={setPhoneNumber}
                              />
                            </div>

                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBackPhone}
                              >
                                {_t("goBack")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.phone) ? false : true
                                }
                              >
                                {_t("next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 6 &&
                      (formData?.isLandlordInComp == "company" ? (
                        <div className="resend-text">
                          <h4>{_t("enterNifNumber")}</h4>
                          <p>{_t("pleaseEnterYourNIFNumber")}</p>
                          <Form className="loginform" onSubmit={handleSubmit}>
                            <div className="auth-form">
                              <div className="forminput row100">
                                <label className="forminput">
                                  {_t("nifNumber")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control user-auth"
                                  name="cif_dni_nie"
                                  onChange={handleInputChange}
                                  value={formData.cif_dni_nie}
                                  placeholder={_t("enterNifNumber")}
                                />
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={handleGoBack}
                                >
                                  {_t("goBack")}
                                </button>
                                <button
                                  className="btn btnorange"
                                  type="submit"
                                  disabled={
                                    haveValue(formData.cif_dni_nie) ||
                                    disabledSubmit
                                      ? false
                                      : true
                                  }
                                >
                                  {_t("submit")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      ) : (
                        <div className="resend-text">
                          <h4>{_t("enterDniNieNumber")}</h4>
                          <p>{_t("pleaseEnterYourDniNieNumber")}</p>
                          <Form className="loginform" onSubmit={handleSubmit}>
                            <div className="auth-form">
                              <div className="forminput row100">
                                <label className="forminput">
                                  {_t("dniNieNumber")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control user-auth"
                                  name="cif_dni_nie"
                                  onChange={handleInputChange}
                                  value={formData.cif_dni_nie}
                                  placeholder={_t("enterDniNieNumber")}
                                />
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={handleGoBack}
                                >
                                  {_t("goBack")}
                                </button>
                                <button
                                  className="btn btnorange"
                                  type="submit"
                                  disabled={
                                    haveValue(formData.cif_dni_nie) ||
                                    disabledSubmit
                                      ? false
                                      : true
                                  }
                                >
                                  {_t("submit")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Modal
          show={goHomeConfirm}
          onHide={(e) => setGoHomeConfirm(false)}
          centered
          className="newmodalui asguarantor"
        >
          <Modal.Header>
            <Modal.Title>{_t("goBackToHome")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="forminput row100">
              {_t(
                "warningContinuingWillResultInTheLossOfYourCurrentInformation"
              )}
              .
            </div>
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline cancel-btn"
                type="button"
                onClick={(e) => setGoHomeConfirm(false)}
              >
                {_t("cancel")}
              </button>
              <button
                className="btn btnorange"
                type="button"
                onClick={handleGoHomeConfirm}
              >
                {_t("goHome")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Container>
          <div className="terms-bar">
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/terms-conditions.html"
              >
                {_t("termsAndConditions")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/terms-conditions.html"
              >
                {_t("termsAndConditions")}
              </Link>
            )}
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/privacy-policy.html"
              >
                {_t("privacyPolicy")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/privacy-policy.html"
              >
                {_t("privacyPolicy")}
              </Link>
            )}
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/cookies-es.html"
              >
                {_t("cookiesPolicy")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/cookies.html"
              >
                {_t("cookiesPolicy")}
              </Link>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default StepForm;
