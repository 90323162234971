import react, { Fragment, useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import AuthContext from "../../context/auth/AuthContext";
import LandLordContext from "../../context/landlord/LandLordContext";
import Loader from "../../components/Loader";
import useTranslator from "../../hooks/useTranslator";
import LoaderBank from "../../components/LoaderBank";
import TenantNavbar from "./TenantNavbar";

const TenantHomeLayout = () => {
  const { _t } = useTranslator();

  const authContext = useContext(AuthContext);
  const { loadUser, loading, bankLoading } = authContext;
  const navigate = useNavigate();


  useEffect(() => {
    let token = localStorage.getItem("token");
    let usertype = localStorage.getItem("usertype");
    if (token && usertype) {
      loadUser(usertype);
      let sharedpath = localStorage.getItem("sharedurl");
      if (usertype === "tenant" && sharedpath) {
        window.location.href = sharedpath;
      }
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div id="main-app-loader">
            <Loader />
          </div>
        </>
      ) : null}
      {bankLoading ? (
        <>
          <div id="main-app-loader">
            <LoaderBank />
          </div>
        </>
      ) : null}

      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12}>
          <TenantNavbar />
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <div className="margin-tenant">
            <div className="margin-tenant-div margin-div">
              <Outlet />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TenantHomeLayout;
