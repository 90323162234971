import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import i18next from "i18next";

import AuthContext from "../context/auth/AuthContext";
import TenantContext from "../context/tenant/TenantContext";
import LandLordContext from "../context/landlord/LandLordContext";

//images and logos import
import logo from "../assets/img/logo.svg";
import homeicon from "../assets/img/home.png";
import profilesharedicon from "../assets/img/profile.png";
import rentalicon from "../assets/img/rental.png";
import offericon from "../assets/img/offer.png";
import { BiHomeHeart } from "react-icons/bi";

import {
  AiFillHome,
  AiOutlineFileText,
  AiOutlinePercentage,
} from "react-icons/ai";
import { MdPayments } from "react-icons/md";
import { RiSecurePaymentLine } from "react-icons/ri";
import useTranslator from "../hooks/useTranslator";
import { haveValue } from "../helper/common";
// import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { _t } = useTranslator();
  // const {t} = useTranslation()

  const navigate = useNavigate();

  const [lngType, setLngType] = useState(
    localStorage.getItem("i18nextLng") || "en-us"
  );

  const authContext = useContext(AuthContext);
  const {
    isLoggedIn,
    logout,
    user,
    userType,
    loadMyNotifications,
    notifications,
  } = authContext;

  const tenantContext = useContext(TenantContext);
  const { loadingTenant, demoFun, loadUser } = tenantContext;

  const landLordContext = useContext(LandLordContext);
  const { loadingLandlord, demoFunLandlord, setProperties, loadAllLandlords } =
    landLordContext;

  const getActiveClass = (args) => {
    let url_location = window.location.href;
    if (url_location.includes(args[0])) {
      return "active";
    }
  };

  const handleRedirectToLandingPage = () => {
    navigate("/home");
  };

  if (userType == "landlord") {
    return (
      <>
        <div id="logo" className="hassidebar" onClick={handleRedirectToLandingPage}>
          <Link to="">
            <span className="big-logo">
              <img src={logo} alt="" />
            </span>
          </Link>
          {/* <span className="small-logo">ADMIN</span> */}
        </div>

        <div id="left-menu" className="landlord_sidebar">
          <ul className="nav nav-pills" role="tablist">
            <li className={`has-sub ${getActiveClass(["home"])}`}>
              <Link to="/home">
                <span>
                  <AiFillHome /> {_t('properties')}
                </span>
              </Link>
            </li>
            <li className={`has-sub ${getActiveClass(["profile-request"])}`}>
              <Link to="/profile-request">
                <span>
                  <AiOutlineFileText /> {_t('tenantProfiles')}
                </span>
              </Link>
            </li>
            <li
              className={`has-sub ${getActiveClass(["payment/property/list"])}`}
            >
              <Link to="/payment/property/list">
                <span>
                  <MdPayments /> {_t('propertyPayments')}
                </span>
              </Link>
            </li>
          </ul>
          <img className="sidebarKey" src="../images/sidebarKey.svg" alt="" />
        </div>
      </>
    );
  }

  if (userType == "tenant") {
    return (
      <>
        {/* <div id="logo" onClick={handleRedirectToLandingPage}>
          <Link to="">
            <span className="big-logo">
              <img src={logo} alt="" />
            </span>
          </Link>
        </div>

        <div id="left-menu">
          <ul className="nav nav-pills" role="tablist">
            <li className={`has-sub ${getActiveClass(["home"])}`}>
              <Link to="/home">
                <span>
                  <AiFillHome /> {_t("Home")}
                </span>
              </Link>
            </li>
            {(user?.onfido_kyc_success == true ||
              (haveValue(user?.gurantor_id)
                ? haveValue(user?.gurantor_id?.paymentScores)
                : haveValue(user?.paymentScores)) ||
              user?.ie_verified) && (
              <li className={`has-sub ${getActiveClass(["profile-shared"])}`}>
                <Link to="/profile-shared">
                  <span>
                    <AiOutlineFileText /> {_t("Profile Shared")}
                  </span>
                </Link>
              </li>
            )}
            {haveValue(user?.assigned_property_id) && (
              <li
                className={`has-sub ${getActiveClass([
                  `/awarded/property/${user?.assigned_property_id}`,
                ])}`}
              >
                <Link to={`/awarded/property/${user?.assigned_property_id}`}>
                  <span>
                    <MdPayments /> {_t("Property Payments")}
                  </span>
                </Link>
              </li>
            )}
          </ul>
          <img className="sidebarKey" src="../images/sidebarKey.svg" alt="" />
        </div> */}
      </>
    );
  }
};

export default Sidebar;
