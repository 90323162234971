import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/auth/AuthContext";
import moment from "moment";
import nexticon from "../../../assets/img/back.png";
import { calculateNowTime } from "../../../services/sharedService";
import useTranslator from "../../../hooks/useTranslator";
import constants from "../../../Utils/constants";
import { toast } from "react-toastify";
import { haveValue } from "../../../helper/common";

const Notification = () => {
  const { _t } = useTranslator();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { notifications, userType, notificationSeen, loadMyNotifications } =
    authContext;

  const [buttoninfo, setButtonInfo] = useState({
    buttonText: "",
    buttonLink: "",
  });

  useEffect(() => {
    if (hash == "#already_awarded") {
      toast.error(_t("youAreAlreadyAwardedAProperty"));
      navigate("/notification");
    }
    if (hash == "#no_longer_available") {
      toast.error(_t("thisPropertyIsNoLongerAvailable"));
      navigate("/notification");
    }
  }, [hash]);

  useEffect(() => {
    if (userType == "landlord") {
      setButtonInfo({ ...buttoninfo, buttonText: _t("sendPropertyLink") });
    }
    if (userType == "tenant") {
      setButtonInfo({ ...buttoninfo, buttonText: _t("propertyInfo") });
    }
    let userid = localStorage.getItem("saveduserid");
    loadMyNotifications(userid, userType, true);
  }, []);

  const checkSeen = (val) => {
    if (val) {
      return "Notifications-wapper";
    } else {
      return "Notifications-wapper bg-wapper";
    }
  };

  const onNotiClick = (notiid) => {
    console.log("notification clicked");
    notificationSeen(notiid);
  };

  const calculateTimeNotification = (notification_date) => {
    const timeago = moment(notification_date).fromNow();
    //console.log(timeago);
    return timeago;
  };

  const placeLink = (notification) => {
    if (notification.notification_type == "guarantor_removed")
      return `/profile-property?sharedprofileid=${notification?.shared_profile_id?._id}`;
    if (notification.body.includes("linked your profile to a address."))
      return `/profile-shared?filter=${notification?.landlord_fname} ${notification?.landlord_lname}`;
    if (notification.notification_type == "property_assigned") {
      // notification?.received_by_tenant?.assigned_property_id == notification?.assigned_property_id?._id
      console.log(notification?.assigned_property_id);
      if (
        !haveValue(notification?.received_by_tenant?.assigned_property_id) &&
        notification?.assigned_property_id?.status == constants.TENANT_PENDING
      ) {
        return `/accept/assigned/property/${notification?.assigned_property_id?._id}`;
      } else if (
        haveValue(notification?.received_by_tenant?.assigned_property_id) &&
        notification?.assigned_property_id?._id ==
          notification?.received_by_tenant?.assigned_property_id
      ) {
        return `/awarded/property/${notification?.received_by_tenant?.assigned_property_id}`;
      } else if (
        notification?.assigned_property_id?.status != constants.TENANT_PENDING
      ) {
        return `/notification#no_longer_available`;
      } else {
        return `/notification#already_awarded`;
      }
    }
    if (notification.notification_type == "profile_sharing") {
      return `/profile-property?sharedprofileid=${notification?.shared_profile_id?._id}`;
    }
  };
  return (
    <>
      <div className="tabs-box-pattern tabs-box-pattern-2">
        <div className="tabs-item d-block padding-full-zero">
          <div className="header-edit text-center space-custom d-flex items-center justify-content-between">
            <div>
              <Link
                to={
                  localStorage.getItem("usertype") == "tenant"
                    ? "/tenant"
                    : "/home"
                }
              >
                <img className="next-icon" src={nexticon} alt="backicon" />
              </Link>
            </div>
            <h3 className="mb-0">{_t("notifications")}</h3>
            <div></div>
          </div>
          <div className="Notifications-row">
            {notifications.length == 0 && (
              <>
                {" "}
                <p style={{ textAlign: "center" }}>
                  {" "}
                  {_t('youHaveNotReceivedAnyNotificationsYet')}
                </p>
              </>
            )}

            {notifications &&
              notifications.map((notification, index) => (
                <>
                  <Link to={placeLink(notification)}>
                    <div
                      key={index}
                      className={checkSeen(notification?.marked_read)}
                    >
                      <div className="notification-leftbar">
                        {userType === "tenant" &&
                        notification?.notification_type !=
                          "guarantor_declined" ? (
                          <>
                            <h4>
                              {notification?.landlord_fname}{" "}
                              {notification?.landlord_lname}{" "}
                              <span>
                                (
                                {notification?.created_by_landlord
                                  ?.landlordtype == "agent"
                                  ? _t("agent")
                                  : _t("landlord")}
                                )
                              </span>
                            </h4>
                          </>
                        ) : (
                          <>
                            <h4>
                              {notification?.tenant_fname}{" "}
                              {notification?.tenant_lname}{" "}
                              <span>({_t("tenant")})</span>
                            </h4>
                          </>
                        )}

                        <p key={index}>
                          {(() => {
                            switch (notification?.notification_type) {
                              case "guarantor_removed":
                                const splited = notification?.body.split(" ");
                                return `${splited[0]} ${splited[1]} ${_t(
                                  "hasRemovedHimselfAsAGuarantorFor"
                                )} ${
                                  notification?.shared_profile_id?.tenant_fname
                                } ${
                                  notification?.shared_profile_id?.tenant_lname
                                }`;

                              case "guarantor_declined":
                                return `${_t(
                                  "guarantorRequestDeclinedBy"
                                )} ${notification?.created_by_tenant?.fname} ${
                                  notification?.created_by_tenant?.lname
                                }`;

                              case "property_assigned":
                                return `${
                                  notification?.created_by_landlord
                                    ?.fname_companyName
                                } ${_t('hasAwardedYouAProperty')}`;

                              case "profile_linked":
                                return `${
                                  notification?.created_by_landlord
                                    ?.fname_companyName
                                } ${_t(
                                  "hasLinkedYourProfileToAAddress"
                                )}.`;

                              case "profile_sharing":
                                return `${
                                  notification?.created_by_tenant?.fname
                                } ${_t("hasSharedProfileWithYou")}`;
                            }
                          })(notification)}
                        </p>
                      </div>

                      <div className="notification-rightbar">
                        <h6>
                          {moment(notification?.createdAt).format("DD/MM/YYYY")}
                        </h6>
                        {notification?.notification_type ==
                        "profile_sharing" ? (
                          <Link
                            onClick={() => onNotiClick(notification?._id)}
                            to={`/profile-property?sharedprofileid=${notification?.shared_profile_id?._id}`}
                          >
                            <img src={nexticon} alt="" />
                          </Link>
                        ) : null}
                        {notification.body.includes("basic") ? (
                          <Link
                            onClick={() => onNotiClick(notification?._id)}
                            to={`/tenant-basic-profile/${notification._id}`}
                          >
                            <img src={nexticon} alt="" />
                          </Link>
                        ) : null}
                        {notification.body.includes("sent") ? (
                          <Link
                            onClick={() => onNotiClick(notification?._id)}
                            to={`/send-link?sharedprofileid=${notification?.shared_profile_id?._id}`}
                          >
                            <img src={nexticon} alt="" />
                          </Link>
                        ) : null}
                        {notification?.notification_type ==
                        "property_assigned" ? (
                          <Link
                            onClick={() => onNotiClick(notification?._id)}
                            to={`/accept/assigned/property/${notification?.assigned_property_id?._id.toString()}`}
                          >
                            <img src={nexticon} alt="" />
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
