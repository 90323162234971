import { useState } from 'react';
import useTranslator from '../../hooks/useTranslator';
import { haveValue } from '../../helper/common';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const PropertyRentDetails = (props) => {
  const { property, sharedProfile, formData, setFormData, handleNextStep } =
    props;
  const { _t } = useTranslator();

  const [error, setError] = useState(false);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth().toString().padStart(2, '0'); // Adding 2 to get month as it is zero-based and payment to start from next month

  // const minDate = `${currentYear}-${currentMonth}-${currentDate.getDate()}`; // Set to 1st day of the current month
  const minDate = currentDate.toISOString().split('T')[0];
  const maxDate = `${currentYear}-${currentMonth}-05`;

  const handleChangeInput = (e) => {
    if (error) {
      setError(false);
    }
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleReview = (e) => {
    e.preventDefault();
    console.log(
      !haveValue(formData?.payment_start) ||
        !haveValue(formData?.rent_amount) ||
        !haveValue(formData?.deposit_amount),
      'IN SUBMIT'
    );
    if (
      !haveValue(formData?.payment_start) ||
      !haveValue(formData?.rent_amount) ||
      !haveValue(formData?.deposit_amount)
    ) {
      setError(true);
    } else {
      handleNextStep();
    }
  };

  return (
    <>
      <div className="rental-datails">
        <h2>{_t('rentalDetails')}</h2>
        <h6>{_t('pleaseProvideYourRentalMoveInDetails')}</h6>
        <form className="details-form" onSubmit={handleReview}>
          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="movein_address">
                {' '}
                {_t('moveInAddress')}
                <span>*</span>{' '}
              </label>
              <input
                type="text"
                placeholder={_t('enterAddress')}
                name="movein_address"
                disabled
                value={`${property?.street} - ${property?.street_number}, ${property?.floor_number}-${property?.flat_number}`}
              />
            </div>
            <div className="col-lg-12">
              <label htmlFor="tenant">
                {' '}
                {_t('tenant')}
                <span>*</span>{' '}
              </label>
              <input
                type="text"
                placeholder={_t('enterTenantName')}
                name="tenant"
                disabled
                value={`${
                  haveValue(sharedProfile?.tenantid?.fname)
                    ? sharedProfile?.tenantid?.fname
                    : ''
                } ${
                  haveValue(sharedProfile?.tenantid?.lname)
                    ? sharedProfile?.tenantid?.lname
                    : ''
                }`}
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="payment_start">
                {_t('leaseStartDate')}
                <span>*</span>
              </label>
              <input
                type="date"
                name="payment_start"
                min={minDate}
                onChange={handleChangeInput}
                value={formData.payment_start}
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="lease_end">{_t('leaseEndDate')}</label>
              <input
                type="date"
                name="lease_end"
                onChange={handleChangeInput}
                value={formData.lease_end}
              />
            </div>

            <div className="col-lg-12">
              <label htmlFor="">
                {'Is it a shared household?'}
                <span>*</span>
              </label>
              <div className="check-radio">
                <div class="form-check radio-check">
                  <label>
                    <input
                      type="radio"
                      name="shared_household"
                      value="yes"
                      onChange={handleChangeInput}
                      checked={formData.shared_household == 'yes'}
                    />
                    {_t('yes')}
                  </label>
                </div>
                <div class="form-check radio-check">
                  <label>
                    <input
                      type="radio"
                      name="shared_household"
                      value="no"
                      onChange={handleChangeInput}
                      checked={formData.shared_household == 'no'}
                    />
                    {_t('no')}
                  </label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <label htmlFor="rent_amount">
                {_t('rentalPaymentAmount')}
                <span>*</span>
              </label>
              <input
                type="number"
                name="rent_amount"
                placeholder={_t('enterPaymentAmount')}
                onChange={handleChangeInput}
                value={formData.rent_amount}
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="deposit_amount">
                {_t('depositAmount')}
                <span>*</span>
              </label>
              <input
                type="number"
                name="deposit_amount"
                placeholder={_t('enterDepositAmount')}
                onChange={handleChangeInput}
                value={formData.deposit_amount}
              />
            </div>
          </div>

          {error && (
            <p className="text-danger alert-text">
              {' '}
              {_t('allFieldsAreMandatoryToBeFilled')}.{' '}
            </p>
          )}
          <button className="btn btngreen">{_t('reviewDetails')}</button>
        </form>
      </div>
    </>
  );
};

export default PropertyRentDetails;
